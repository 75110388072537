import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

interface TermsDialogProps {
  onClose: () => void;
}

const TermsAndConditionsDialog: React.FC<TermsDialogProps> = ({ onClose }) => (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Terms and Conditions</DialogTitle>
      <DialogContent dividers>
      <Typography variant="body1" paragraph>
        The following terms and conditions apply to the professional services specified in the attached quote from LanguageLine. These Terms of Service along with the accompanying Estimate, constitutes the entire Agreement between LanguageLine and you (the &quot;Customer&quot;) and supersedes all prior oral or written understanding or statement with respect to the subject matter of this Agreement.
      </Typography>
      <Typography
        variant="h5"
        align="center"
        gutterBottom
        style={{ marginTop: '16px', fontWeight: 'bold' }}
      >
        Terms of Service
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>1. Pricing:</strong> Prices for the Services shall be as specified in this Estimate. Estimates are based upon (i) the documents or files provided by Customer, (ii) specifications and information originally submitted by Customer, and (iii) the delivery requirements requested by Customer. Adjustments to the Estimate may be necessary if specifications are changed or added, or if work not covered in the original quotation is requested by Customer. Estimates are only valid for thirty days or as otherwise specified in writing.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>2. Orders:</strong> Orders authorized by Customer may only be canceled by Customer if Customer pays for all of the work performed by LanguageLine, including all fees, costs and expenses incurred by LanguageLine in connection with the order prior to cancellation. If Customer changes the original text, alters specifications, or adds new specifications after Customer has authorized LanguageLine to begin work, the changes, alterations, or additions may result in additional charges and adjustments of deadlines.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>3. Terminology:</strong> Unless Customer provides terminological reference material like glossaries or terminology lists, LanguageLine will use its best judgment in the selection of terms pertinent to a given field. Customer will be charged for and will pay for all changes to such terms. Any changes to terminology in raw machine translations may result in additional charges to Customer.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>4. Appearance:</strong> When the Services include formatting, typesetting, page layout, or artwork, LanguageLine will seek the closest match practicable between the appearance of the original and that of the finished product or will layout as Customer specifies. Unless the Estimate states otherwise, LanguageLine does not guarantee that the format, fonts, typefaces, point sizes, text density, artwork, colors, paper, and other elements of printed documents it chooses and those of the original will be identical. Translated documents are sometimes longer or shorter than the original, and technical or other considerations may result in elements of appearance different from the original.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>5. Editing:</strong> Any editing or alteration of the delivered product required by Customer in cases where the style or other matter had been left to the judgment of LanguageLine, including stylistic or preferential linguistic changes, may result in additional charges to Customer. For human translation or machine translation with post editing projects, translation errors will be corrected at no extra charge.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>6. Change Orders:</strong> Change order requests from Customer will be analyzed by LanguageLine for cost and schedule impact. If, in LanguageLine&apos;s reasonable judgment, the requested changes can be implemented without requiring additional time or resources and without affecting LanguageLine&apos;s ability to maintain the project schedule, LanguageLine will implement the change at no additional cost to Customer. Otherwise, prior to proceeding with any changes, LanguageLine will provide Customer with a written change order quote for the additional work that includes (1) price change and (2) impact on schedule. Customer may, at its discretion, accept or reject LanguageLine&apos;s change order proposal. Change orders shall be considered effective upon written approval. Each party shall use its best efforts to respond as expeditiously as possible to such change requests and change order proposals.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>7. Payment:</strong> Unless otherwise stated in an Estimate, invoices will be issued at the end of the month in which the project is delivered. (b) All errors, claims, or requests for adjustment must be presented within thirty (30) days after the date of delivery or such work will be deemed to have been accepted. (c) Charges due and unpaid thirty (30) days after invoice shall bear interest from the date payment is due at the rate of one and one-half percent (1 1/2 %) per month (eighteen percent (18%) per annum).
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>8. Customer Property:</strong> At Customer&apos;s request, Customer property delivered to LanguageLine for use in the providing of the Services will be returned to Customer upon completion of the work by LanguageLine without any liability for loss or damage.
      </Typography>
      <Typography variant="body1" paragraph>
        <strong>ADDITIONAL Limited Warranty for Human Translation and post-edited Machine Translation: </strong> In addition to any other Limited Warranty that may exist between the parties, LanguageLine&apos;s sole obligation for Services is the re-performance, at no additional charge to Customer, of that portion of those Services that LanguageLine and Customer agree to be defective. Defects include translation errors, but do not include subjective elements of style. LanguageLine shall correct any agreed upon defect within thirty (30) days of notice from Customer.
      </Typography>
        <Typography variant="body1" paragraph>
          <strong>10. No Raw Machine Translation Warranties:</strong> There are no warranties of any kind for machine translation that has not been post-edited (i.e., &quot;raw&quot; machine translation).
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );

export default TermsAndConditionsDialog;
