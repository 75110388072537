import * as React from "react";
import { Alert, Box, Typography } from "@mui/material";
import { CustomFieldDefinition, WorkflowOption } from "gen/clients/llts";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { UserContactSection } from "../../../CreateProjectPage/components/UserContactSection/UserContactSection";
import { FilesSection } from "../../../CreateProjectPage/components/FilesSection/FilesSection";
import { ProjectInfoSection } from "../../../CreateProjectPage/components/ProjectInfoSection/ProjectInfoSection";
import { FileUploadLimits } from "../../../../components/formikFields/DropzoneField/FileUploadLimits";

interface Props {
  clientId?: string;
  projectCustomFieldDefinitions?: CustomFieldDefinition[];
  userCustomFieldDefinitions?: CustomFieldDefinition[];
  workflowOptions?: WorkflowOption[];
  hideContactSection?: boolean;
  contactSectionTitle?: React.ReactNode;
  documentInfoTitle?: React.ReactNode;
  projectInfoTitle?: React.ReactNode;
  footerMessage?: string;
  isSubmitting?: boolean;
  fileUploadLimits?: FileUploadLimits;
}

const CreateJobRequestForm: React.FC<Props> = (
  {
    clientId,
    projectCustomFieldDefinitions,
    userCustomFieldDefinitions,
    workflowOptions,
    hideContactSection,
    contactSectionTitle,
    documentInfoTitle,
    projectInfoTitle,
    footerMessage,
    isSubmitting,
    fileUploadLimits
  }) => {
  const { t } = useTranslation();
  const { setSubmitting } = useFormikContext();

  React.useEffect(() => {
    setSubmitting(!!isSubmitting);
  }, [isSubmitting]);
  
  return (
    <>
      {!hideContactSection &&
        <>
          <Typography
            variant="h4">{contactSectionTitle || t("createProject.createProjectForm.contactSectionTitle")}</Typography>
          <UserContactSection userCustomFieldDefinitions={userCustomFieldDefinitions} showCompanyName={true}/>
        </>
      }

      {/* Document info section */}
      <Box marginTop={6}>
        <Typography
          variant="h4">{documentInfoTitle || t("createProject.createProjectForm.documentInfoTitle")}</Typography>
        <FilesSection fileUploadLimits={fileUploadLimits}/>
      </Box>

      {/* Languages section */}
      <Box marginTop={10}>
        <Typography
          variant="h4">{projectInfoTitle || t("createProject.createProjectForm.projectInfoTitle")}</Typography>
        <ProjectInfoSection
          clientId={clientId}
          isProjectNameVisible={true}
          projectCustomFieldDefinitions={projectCustomFieldDefinitions}
          workflowOptions={workflowOptions}
        />
      </Box>
      {footerMessage &&
        <Alert severity="warning" sx={{ mt: 5 }}>
          {footerMessage.split("\n").map(line => (<div>{line}<br/></div>))}
        </Alert>
      }
    </>
  );
};

export { CreateJobRequestForm };
