declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    LO: any;
  }
}

export enum LOEvent {
  PROJECT_CREATED = "Project Created",
  QUOTE_CREATED = "Quote Created"
}

export class LuckyOrangeUtils {
  /**
   * Identifies the user with the given information. This should be called on app load so that the user's
   * information is available to the Lucky Orange SDK.
   * @param username
   * @param email
   * @param firstName
   * @param lastName
   */
  public static identifyUser({ username, email, firstName, lastName }: {
    username: string,
    email: string,
    firstName?: string | null,
    lastName?: string | null
  }): void {
    try {
      window.LO?.visitor?.identify(username, {
        email,
        name: firstName && lastName ? `${firstName} ${lastName}` : username
      });
    } catch (e) {
      window.console.error(e);
    }
  }

  /**
   * Tracks an event with the given name and properties.
   * @param eventName
   * @param eventProperties
   */
  public static trackEvent(eventName: LOEvent, eventProperties?: Record<string, string | number | boolean | undefined>): void {
    try {
      window.LO?.events?.track(eventName, eventProperties || {});
    } catch (e) {
      window.console.error(e);
    }
  }
}