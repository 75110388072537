import * as React from "react";
import { useMutation } from "react-query";
import { Container, CssBaseline, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { ClientConfig, ClientConfigsService } from "../../../../../gen/clients/llts";
import { TextInputField } from "../../../../../components/formikFields/TextInputField/TextInputField";
import { SelectOneField } from "../../../../../components/formikFields/SelectOneField/SelectOneField";
import { Hero } from "../../../../../components/Hero/Hero";
import { ErrorPage } from "../../../../../components/ErrorPage/ErrorPage";
import { validateEmailFormat } from "../../../../../utils/stringUtils";

interface Props {
  onSuccess: (email: string, clientConfig?: ClientConfig) => void;
}

enum FieldNames {
  email = "email",
  company = "company"
}

type Values = Record<string, unknown>;

const InitialEntryForm: React.FC<Props> = ({ onSuccess }) => {
  const { t } = useTranslation();
  const [clientValidationMessage, setClientValidationMessage] = React.useState<string>();

  const {
    mutate: listClientConfigs,
    data: clientConfigs,
    isLoading,
    error
  } = useMutation(ClientConfigsService.listClientConfigs);

  const onSubmit = React.useCallback(async (values: Values) => {
    const email = values[FieldNames.email] as string;
    if (!clientConfigs) {
      const emailDomain = email.substring(email.indexOf("@") + 1);
      await listClientConfigs({ emailDomain }, {
        // If found only one client config matching the domain, then call onSuccess
        onSuccess: (clientConfs) => {
          if (clientConfs.length === 1) {
            const clientConf = clientConfs[0];
            if (clientConf.status !== "ACTIVE") {
              setClientValidationMessage(t("createProject.unsignedUser.validationMessages.inactiveClient"));
            } else {
              onSuccess(email, clientConf);
            }
          } else if (clientConfs.length === 0) {
            onSuccess(email);
          }
        }
      });
    } else if (clientConfigs.length > 1) {
      const selectedClientId = values[FieldNames.company];
      const selectedClientConfig = clientConfigs.find(c => c.clientId === selectedClientId);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onSuccess(email, selectedClientConfig!);
    }
  }, [onSuccess, clientConfigs]);

  const validate = React.useCallback((values: Values) => {
    const errors: Partial<Values> = {};
    // Validate email
    const email = values.email as string;
    if (!email) {
      errors.email = t("common.validation.required");
    } else if (!validateEmailFormat(email)) {
      errors.email = t("createProject.unsignedUser.validationMessages.emailFormat");
    }
    return errors;
  }, []);

  const Header = React.useMemo(() => (
    <Hero title={t("createProject.unsignedUser.header.title")}>
      <p>
        {t("createProject.unsignedUser.header.content1")}
      </p>
      {/* eslint-disable-next-line react/no-danger */}
      <p dangerouslySetInnerHTML={{ __html: t("createProject.unsignedUser.header.content2") }}/>
    </Hero>
  ), []);

  if (error) {
    return <ErrorPage apiError={error}/>
  }

  if (clientValidationMessage) {
    return (
      <>
        <CssBaseline/>
        {Header}
        <Container maxWidth="md">
          <Alert severity="error">
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: clientValidationMessage }}/>
          </Alert>
        </Container>
      </>
    );
  }

  return (
    <>
      <CssBaseline/>
      {Header}
      <Container maxWidth="md">
        <Formik
          initialValues={{
            [FieldNames.email]: ""
          }}
          onSubmit={onSubmit}
          validate={validate}
          validateOnMount={false}
        >
          <Form noValidate={true} autoComplete="off" autoCorrect="off">
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <TextInputField
                  name={FieldNames.email}
                  label={t("createProject.unsignedUser.email.label")}
                  placeholder={t("createProject.unsignedUser.email.placeholder")}
                  type="email"
                  required={true}
                />
              </Grid>
              {clientConfigs && clientConfigs.length > 1 &&
                <Grid item xs={12}>
                  <SelectOneField
                    name={FieldNames.company}
                    label={t("createProject.unsignedUser.company")}
                    options={clientConfigs
                      .filter(c => c.status === "ACTIVE")
                      .map(clientConfig => ({
                        label: clientConfig.companyName,
                        value: clientConfig.clientId
                      }))
                      .sort((a, b) => a.label.localeCompare(b.label))
                  }
                    required={true}
                  />
                </Grid>
              }
              <Grid item xs={12}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  {t("createProject.unsignedUser.continue")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Container>
    </>
  );
};

export { InitialEntryForm };
