import * as React from "react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik/dist/types";
import { Alert, Box, Container, CssBaseline, Grid } from "@mui/material";
import { Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { DEFAULT_COUNTRY_CODE } from "utils/constants";
import { useUserSession } from "../../../hooks/useUserSession";
import {
  ClientConfigsService, CustomFieldDefinition,
  CustomFieldDefinitionCategory, JobRequestOrigination,
  JobRequestType,
  ProjectSubmissionMode,
  UserAccountsService,
} from "../../../gen/clients/llts";
import {
  UserContactSectionField,
  UserContactSectionFormValues
} from "../../CreateProjectPage/components/UserContactSection/UserContactSection";
import {
  ContentInputMethod,
  FilesSectionFieldNames,
  FilesSectionFormValues
} from "../../CreateProjectPage/components/FilesSection/FilesSection";
import {
  ProjectInfoFieldName,
  ProjectInfoFormValues
} from "../../CreateProjectPage/components/ProjectInfoSection/ProjectInfoSection";
import { useSubmitJobRequest } from "../hooks/useSubmitJobRequest";
import {
  CreateProjectFormValidator
} from "../../CreateProjectPage/components/CreateProjectForm/CreateProjectFormValidator";
import { FormHeader } from "../../CreateProjectPage/components/FormHeader/FormHeader";
import { SnackbarApiError } from "../../../components/SnackbarApiError/SnackbarApiError";
import { ScrollToFieldError } from "../../../components/ScrollToFieldError/ScrollToFieldError";
import { CreateJobRequestForm } from "../components/CreateJobRequestForm/CreateJobRequestForm";
import { AlertDialog } from "../../../components/AlertDialog/AlertDialog";
import { LinearProgressWithLabel } from "../../../components/LinearProgressWithLabel/LinearProgressWithLabel";
import { LoadingButton } from "../../../components/LoadingButton/LoadingButton";
import { ErrorPage } from "../../../components/ErrorPage/ErrorPage";
import { LoadingPage } from "../../../components/LoadingPage/LoadingPage";
import { createProjectName } from "../../CreateProjectPage/utils/projectUtils";
import { sanitizeFieldName } from "../../../components/formikFields/formikUtils";

interface Props {
  origination: JobRequestOrigination;
  onSuccess: (jobRequestId: string) => void;
  hideSubmitForEstimate?: boolean;
  hideSubmitForTranslation?: boolean;
  externalReferenceId?: string;
}

type Values = UserContactSectionFormValues & FilesSectionFormValues & ProjectInfoFormValues;

const AuthenticatedCreateJobRequestPage: React.FC<Props> = ({
                                                              origination,
                                                              onSuccess,
                                                              hideSubmitForEstimate,
                                                              hideSubmitForTranslation,
                                                              externalReferenceId
                                                            }) => {
  const { t } = useTranslation();
  const formikRef = React.useRef<FormikProps<Values>>(null);
  const [requestType, setRequestType] = React.useState<JobRequestType>();
  const { username, email } = useUserSession();

  const { data: userAccount, isLoading: isUserAccountLoading, error: userAccountError } =
    useQuery(["getUserAccount", username], {
      queryFn: UserAccountsService.getUserAccount
    });
  const formValues = formikRef.current?.values;
  const clientId = userAccount?.clientConfig?.clientId;
  const {
    data: projectCustomFieldDefinitions,
    isLoading: areCustomFieldDefinitionsLoading,
    error: customFieldDefinitionsError
  } =
    useQuery(["listCustomFieldDefinitions", clientId, CustomFieldDefinitionCategory.PROJECT], {
      enabled: !!clientId,
      queryFn: () => ClientConfigsService.getCustomFieldDefinitions(
        { clientId: clientId || "", category: CustomFieldDefinitionCategory.PROJECT }
      )
    });

  const isPageLoading = isUserAccountLoading || areCustomFieldDefinitionsLoading;
  const pageError = userAccountError || customFieldDefinitionsError;

  const {
    submitJobRequest,
    isSubmitting,
    error: submitJobRequestError,
    jobRequestCreated,
    jobRequestId,
    progress
  } = useSubmitJobRequest({
    origination,
    formValues,
    requestType,
    externalReferenceId,
    projectCustomFieldDefinitions
  });

  React.useEffect(() => {
    if (jobRequestCreated && jobRequestId) {
      onSuccess(jobRequestId);
    }
  }, [jobRequestCreated, jobRequestId]);

  const validate = React.useCallback((values: Values) => {
    const errors: Partial<Values> = {};
    const documentInputErrors = CreateProjectFormValidator.validate(values as unknown as Record<string, unknown>, t);
    return { ...errors, ...documentInputErrors };
  }, []);

  const initialValues = React.useMemo(() => {
    const standardFieldValues = {
      [UserContactSectionField.companyName]: "",
      [UserContactSectionField.email]: "",
      [UserContactSectionField.firstName]: "",
      [UserContactSectionField.lastName]: "",
      [UserContactSectionField.address1]: "",
      [UserContactSectionField.address2]: "",
      [UserContactSectionField.city]: "",
      [UserContactSectionField.province]: "",
      [UserContactSectionField.zip]: "",
      [UserContactSectionField.country]: DEFAULT_COUNTRY_CODE,
      [UserContactSectionField.phone]: "",
      [ProjectInfoFieldName.sourceLanguage]: null,
      [ProjectInfoFieldName.targetLanguages]: [],
      [ProjectInfoFieldName.notes]: "",
      [ProjectInfoFieldName.projectName]: createProjectName(email),
      [ProjectInfoFieldName.xtrfServiceId]: "",
      [FilesSectionFieldNames.inputMethod]: ContentInputMethod.FILES,
      [FilesSectionFieldNames.files]: [],
      [FilesSectionFieldNames.fileLinks]: [],
      [FilesSectionFieldNames.textInput]: "",
      [FilesSectionFieldNames.includeReferenceFiles]: [],
      [FilesSectionFieldNames.referenceFiles]: [],
    };
    const customFieldValues: Record<string, string | string[]> = {};
    projectCustomFieldDefinitions?.forEach((customFieldDefinition: CustomFieldDefinition) => {
      customFieldValues[sanitizeFieldName(customFieldDefinition.name)] = customFieldDefinition.defaultValue || "";
    });
    return {
      ...standardFieldValues,
      ...customFieldValues
    }
  }, [projectCustomFieldDefinitions]);

  if (isPageLoading) {
    return <LoadingPage/>;
  }
  if (pageError) {
    return <ErrorPage apiError={pageError}/>;
  }

  return (
    <>
      <CssBaseline/>
      <FormHeader
        title={t("createProject.signedInUser.header.title")}
        bannerUrl={userAccount?.clientConfig?.bannerUrl}
      >
        <p>
          {t("createProject.signedInUser.header.content1")}
        </p>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: t("createProject.signedInUser.header.content2") }}/>
      </FormHeader>
      <Container maxWidth="md">
        {userAccount?.clientConfig?.instructionalText &&
          <Box sx={{ mb: 2 }}>
            <Alert severity="info">
              {userAccount?.clientConfig?.instructionalText.split("\n").map(line => <p key={line}>{line}</p>)}
            </Alert>
          </Box>
        }
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {userAccount && userAccount.clientConfig &&
              <Formik
                initialValues={initialValues}
                onSubmit={submitJobRequest}
                validate={validate}
                validateOnMount={false}
                innerRef={formikRef}
              >
                <Form noValidate={true} autoComplete="off" autoCorrect="off">
                  <ScrollToFieldError/>
                  <CreateJobRequestForm
                    clientId={userAccount.clientConfig.clientId}
                    projectCustomFieldDefinitions={projectCustomFieldDefinitions}
                    workflowOptions={userAccount.clientConfig.selectableWorkflows}
                    hideContactSection={true}
                    documentInfoTitle={t("createProject.signedInUser.documentInfoTitle")}
                    projectInfoTitle={t("createProject.signedInUser.projectInfoTitle")}
                    footerMessage={userAccount.clientConfig.projectSubmissionFooterMessage}
                    isSubmitting={isSubmitting}
                  />
                  <Box marginY={5}>
                    {!hideSubmitForEstimate &&
                      (userAccount.clientConfig.projectSubmissionMode === ProjectSubmissionMode.SUBMIT_FOR_ESTIMATION ||
                        userAccount.clientConfig.projectSubmissionMode === ProjectSubmissionMode.BOTH ||
                        !userAccount.clientConfig.projectSubmissionMode) &&
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{ mr: 1 }}
                        isLoading={isSubmitting && requestType === JobRequestType.QUOTE_REQUEST}
                        onClick={() => {
                          setRequestType(JobRequestType.QUOTE_REQUEST);
                        }}
                      >
                        {t("createProject.createProjectForm.sendForEstimation")}
                      </LoadingButton>
                    }
                    {!hideSubmitForTranslation &&
                      (userAccount.clientConfig.projectSubmissionMode === ProjectSubmissionMode.SUBMIT_FOR_TRANSLATION ||
                        userAccount.clientConfig.projectSubmissionMode === ProjectSubmissionMode.BOTH ||
                        !userAccount.clientConfig.projectSubmissionMode) &&
                      <LoadingButton
                        type="submit"
                        variant="contained"
                        size="large"
                        isLoading={isSubmitting && requestType === JobRequestType.TRANSLATION_REQUEST}
                        onClick={() => {
                          setRequestType(JobRequestType.TRANSLATION_REQUEST);
                        }}
                      >
                        {t("createProject.createProjectForm.sendToTranslation")}
                      </LoadingButton>
                    }
                  </Box>
                </Form>
              </Formik>
            }
          </Grid>
        </Grid>
        {isSubmitting &&
          <AlertDialog title={t("createProject.createProjectForm.submittingAlert.title")}>
            <Stack spacing={1}>
              <Box>
                {t("createProject.createProjectForm.submittingAlert.text")}
              </Box>
              <Box sx={{ width: "100%" }}>
                <LinearProgressWithLabel value={progress || 0}/>
              </Box>
            </Stack>
          </AlertDialog>
        }
        {submitJobRequestError &&
          <SnackbarApiError error={submitJobRequestError}/>
        }
      </Container>
    </>
  );
};

export { AuthenticatedCreateJobRequestPage };
