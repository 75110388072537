import * as React from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useQuery } from "react-query";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Divider from "@mui/material/Divider";
import {
  JobRequest,
  JobRequestFileCategory,
  JobRequestsService,
  JobRequestType
} from "../../../../gen/clients/llts";
import { JobRequestUtils } from "../../../../utils/jobRequestUtils";
import { LabelValue } from "../../../../components/LabelValue/LabelValue";
import { formatDate } from "../../../../utils/dateUtils";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import { ErrorPage } from "../../../../components/ErrorPage/ErrorPage";
import { JobRequestState } from "../../../../components/jobRequests/JobRequestState/JobRequestState";
import { SourceFilesTab } from "./SourceDocumentsTab/SourceFilesTab";
import { QuoteTab } from "./QuoteTab/QuoteTab";
import { DeliverablesTab } from "./DelivrablesTab/DeliverablesTab";

interface PathParams {
  jobRequestId: string;
}

enum TabId {
  sourceDocuments = "sourceDocuments",
  quote = "quote",
  deliverables = "deliverables"
}

const JobRequestDetailsPage: React.FC = () => {
  const history = useHistory();
  const { jobRequestId } = useParams<PathParams>();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState(TabId.sourceDocuments);
  const { data: jobRequest, isLoading, refetch, error } = useQuery(["getJobRequest", jobRequestId], {
    queryFn: () => JobRequestsService.getJobRequest({ jobRequestId }),
    refetchInterval: (jr: JobRequest | undefined) => JobRequestUtils.isInProgress(jr?.status) ? 10000 : false,
    cacheTime: 0
  });

  const onReturnClick = React.useCallback(() => history.push("/coupa"), []);

  const isQuoteTabVisible = React.useMemo(() =>
    jobRequest?.requestType === JobRequestType.QUOTE_REQUEST ||
    jobRequest?.files?.some(f => f.category === JobRequestFileCategory.QUOTE_CONFIRMATION_DOCUMENT), [jobRequest]);

  if (isLoading || !jobRequest) {
    return <LoadingPage/>;
  }
  if (error) {
    return <ErrorPage apiError={error}/>;
  }

  return (
    <Container maxWidth="xl" sx={{ my: 4 }}>
      <Stack spacing={3}>
        <Stack direction="row" justifyItems="center" alignItems="center">
          <IconButton onClick={onReturnClick}>
            <ArrowBackIosIcon/>
          </IconButton>
          <Box flexGrow={1}>
            <Typography variant="h5">{jobRequest.name}</Typography>
          </Box>
        </Stack>
        <Card sx={{ p: 2, mt: 1 }}>
          <Typography variant="h6" mb={1}>Summary</Typography>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={12} sm={6}>
              <Stack spacing={1}>
                <LabelValue
                  label="Status"
                  value={<JobRequestState jobRequest={jobRequest}/>}/>
                <LabelValue
                  label={t("machineTranslation.fileTranslation.requests.submittedOn")}
                  value={<Typography>{formatDate(jobRequest.createTime)}</Typography>}
                />
              </Stack>
            </Grid>
            <Grid item={true} xs={12} sm={6}>
              <Stack spacing={1}>
                <LabelValue
                  label={t("machineTranslation.fileTranslation.requests.sourceLanguage")}
                  value={<Typography>{jobRequest.sourceLanguage?.name}</Typography>}
                />
                <LabelValue
                  label={t("machineTranslation.fileTranslation.requests.targetLanguages")}
                  value={<Typography>{jobRequest.targetLanguages?.map(l => l.name)
                    .sort((l1, l2) => l1.localeCompare(l2))
                    .join(", ")}</Typography>}
                />
              </Stack>
            </Grid>
          </Grid>
        </Card>

        <Card>
          <Tabs
            defaultValue={TabId.sourceDocuments}
            value={selectedTab}
            onChange={(e, value) => setSelectedTab(value)}
          >
            <Tab value={TabId.sourceDocuments} label="Source Documents" sx={{ fontWeight: "bold" }}/>
            {isQuoteTabVisible &&
              <Tab value={TabId.quote} label="Quote" sx={{ fontWeight: "bold" }}/>
            }
            <Tab value={TabId.deliverables} label="Deliverables" sx={{ fontWeight: "bold" }}/>
          </Tabs>
          <Divider/>
          <Box p={3} minHeight={400}>
            {selectedTab === TabId.sourceDocuments &&
              <SourceFilesTab jobRequest={jobRequest}/>
            }
            {selectedTab === TabId.quote &&
              <QuoteTab jobRequest={jobRequest} refetchJobRequest={refetch}/>
            }
            {selectedTab === TabId.deliverables &&
              <DeliverablesTab jobRequest={jobRequest}/>
            }
          </Box>
        </Card>
      </Stack>
    </Container>
  );
};

export { JobRequestDetailsPage };
