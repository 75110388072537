import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { LLS_HOME_URL, TELEPERFORMANCE_PRIVACY_URL } from "../../config/urls";

const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <Box
        sx={{
          background: "#002b50",
          color: "#fff",
          fontSize: 14,
          padding: "19px 0 19px 10%;",
          "& a": {
            color: "#fff",
            textDecoration: "underline"
          }
        }}
        dangerouslySetInnerHTML={{
          __html: t("footer.copyright", {
            teleperformancePrivacy: TELEPERFORMANCE_PRIVACY_URL,
            llsDotCom: LLS_HOME_URL,
            fullYear: new Date().getFullYear()
          })
        }}
      />
    </footer>
  );
};

export default Footer;