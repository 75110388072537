import * as React from "react";
import { useHistory } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";
import {
  AuthenticatedCreateJobRequestPage
} from "../../CreateJobRequestPage/AuthenticatedCreateJobRequestPage/AuthenticatedCreateJobRequestPage";
import { JobRequestOrigination } from "../../../gen/clients/llts";
import { COUPA_REQUEST_ID_SESSION_STORAGE_KEY } from "../../../utils/constants";

const CreateCoupaQuotePage: React.FC = () => {
  const history = useHistory();
  const [requestId, storeRequestId] = useSessionStorage(COUPA_REQUEST_ID_SESSION_STORAGE_KEY, "");

  const onSuccess = React.useCallback(() => {
    storeRequestId("");
    history.push("/coupa");
  }, []);

  if (requestId) {
    return (
      <AuthenticatedCreateJobRequestPage
        origination={JobRequestOrigination.COUPA}
        hideSubmitForTranslation={true}
        externalReferenceId={requestId}
        onSuccess={onSuccess}
      />
    );
  }

  return (<></>);
};

export { CreateCoupaQuotePage };
