/* eslint-disable @typescript-eslint/no-explicit-any,react/jsx-props-no-spreading */
import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { StoreContext } from "redux-react-hook";
import { createBrowserHistory } from "history";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import AuthEventListener from "../../AuthEventListener";
import { configureStore } from "../../store/store";
import logging from "../../config/logging";
import AppHeader from "../AppHeader/AppHeader";
import Routes from "../Routes";
import "@fontsource/roboto";
import Footer from "../Footer/Footer";
import { LuckyOrangeUserIdentifier } from "../LuckyOrangeUserIdentifier/LuckyOrangeUserIdentifier";
import { escsMuiThemeOptions } from "./muiTheme";

const SUPPORTED_SIGN_IN_PATHS = [
  "/changePassword"
]

const awsconfig = {
  aws_user_pools_id: process.env.REACT_APP_USERPOOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USERPOOL_WEBCLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: [
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: SUPPORTED_SIGN_IN_PATHS.includes(window.location.pathname) ?
      `${window.location.origin}${window.location.pathname}`
      : `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: "code"
  },
  federationTarget: "COGNITO_USER_POOLS"
};
Amplify.configure(awsconfig);

const history = createBrowserHistory();
const store = configureStore(history);
// noinspection JSIgnoredPromiseFromCall
AuthEventListener.init(store);

const theme = createTheme(escsMuiThemeOptions)

const App: React.FC = () => {
  useEffect(() => {
    logging.info("Loading application.");
  }, []);
  return (
    <Provider store={store}>
      <StoreContext.Provider value={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <LuckyOrangeUserIdentifier/>
            <CssBaseline>
              <AppHeader/>
              <Box sx={{ flex: 1 }}>
                <Routes/>
              </Box>
              <Footer/>
            </CssBaseline>
          </BrowserRouter>
        </ThemeProvider>
      </StoreContext.Provider>
    </Provider>
  );
};

export default App;
