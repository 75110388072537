import { SelectOption } from "@mui/base";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import {
  JobRequestFile,
  JobRequestFileCategory, JobRequestOrigination, LltsLanguage,
  MachineTranslationService,
  UtilsService
} from "gen/clients/llts";
import { Form, Formik, FormikProps } from "formik";
import { useS3FileUpload } from "hooks/useS3FileUpload/useS3FileUpload";
import { createFileKey } from "utils/s3Utils";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { LoadingPage } from "components/LoadingPage/LoadingPage";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import Grid from "@mui/material/Grid";
import {
  SelectOneAutocompleteField
} from "components/formikFields/SelectOneAutocompleteField/SelectOneAutocompleteField";
import Stack from "@mui/material/Stack";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import {
  SelectManyAutocompleteField
} from "components/formikFields/SelectManyAutocompleteField/SelectManyAutocompleteField";
import Box from "@mui/material/Box";
import { DropzoneField } from "components/formikFields/DropzoneField/DropzoneField";
import Typography from "@mui/material/Typography";
import { AlertDialog } from "components/AlertDialog/AlertDialog";
import { LinearProgressWithLabel } from "components/LinearProgressWithLabel/LinearProgressWithLabel";
import { FormFieldObserver } from "components/formikFields/FormFieldObserver/FormFieldObserver";
import { TextField } from "@mui/material";
import { useUserSession } from "hooks/useUserSession";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createProjectName } from "../../../CreateProjectPage/utils/projectUtils";
import { MtJobRequestList } from "./MtJobRequestList/MtJobRequestList";

const SUPPORTED_FILE_TYPES = [".docx", ".doc", ".pptx", ".ppt", ".xlsx", ".xls", ".pdf", ".txt"];
const ENGLISH_ID = "enu";
const MAX_TOTAL_FILES_SIZE_BYTES = 250 * 1024 * 1024; // 250 MB

enum FieldNames {
  sourceLanguage = "sourceLanguage",
  targetLanguages = "targetLanguages",
  files = "files"
}

interface FormValues {
  [FieldNames.sourceLanguage]?: SelectOption<string>;
  [FieldNames.targetLanguages]?: SelectOption<string>[];
  [FieldNames.files]: File[];
}

const MAX_TARGET_LANGUAGES = 10;

const FileTranslationMainPage: React.FC = () => {
  const { t } = useTranslation();
  const { email } = useUserSession();
  const listRefetchRef = React.useRef<(() => void | undefined)>();
  const [isTargetLanguageDisabled, setTargetLanguageDisabled] = React.useState(false);
  const [timestamp, setTimestamp] = React.useState<number>(new Date().getTime());
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { data: mtSettings, isLoading: areMtSettingsLoading, error: mtSettingsError } =
    useQuery(["getMtSettings"], {
      queryFn: MachineTranslationService.getMachineTranslationSettings
    });
  const { data: lltsLanguages, isLoading: areLltsLanguagesLoading, error: lltsLanguagesError } =
    useQuery(["listAzureLltsLanguages"], {
      queryFn: () => UtilsService.listLltsLanguages({
        azureIdExists: true
      })
    });
  const lltsLanguageById: Record<string, LltsLanguage> = React.useMemo(() =>
      (lltsLanguages || []).reduce((prevValue, currentValue) => {
        // eslint-disable-next-line no-param-reassign
        prevValue[currentValue.id] = currentValue;
        return prevValue;
      }, {} as Record<string, LltsLanguage>)
    , [lltsLanguages]);
  const languageOptions = React.useMemo(
    () =>
      mtSettings?.supportedLltsLanguages
        ?.filter(
          (langId) =>
            lltsLanguageById[langId] && !lltsLanguageById[langId].isDisabled
        )
        .map((langId) => ({
          label: lltsLanguageById[langId]?.name || langId,
          value: langId
        }))
        .sort(SELECT_OPTION_COMPARATOR) || [],
    [mtSettings, lltsLanguageById]
  );

  const formikRef = React.useRef<FormikProps<FormValues>>(null);
  const formValues = formikRef.current?.values;

  const {
    mutate: createPresignedPost,
    data: presignedPost,
    isLoading: isCreatingPresignedPost,
    error: createPresignedPostError,
    reset: resetPresignedPost
  } = useMutation(UtilsService.createTempFileUploadPresignedPost);

  const {
    invoke: uploadFiles,
    isUploading: areFilesUploading,
    isSuccess: areFilesUploaded,
    error: filesUploadError,
    uploadProgress,
    reset: resetS3FileUpload
  } = useS3FileUpload();

  const {
    mutate: submitTranslationRequest,
    data: jobRequestInfo,
    isLoading: isSubmittingTranslationRequest,
    error: submitTranslationRequestError,
    isSuccess: translationRequestSubmitted
  } = useMutation(MachineTranslationService.translateFiles)

  // --- Upload files when presigned post is created ---
  React.useEffect(() => {
    if (!presignedPost || !formValues) {
      return;
    }
    const files: File[] = formValues[FieldNames.files];
    uploadFiles({ files, presignedPost });
  }, [presignedPost]);

  // --- Submit translation request when file uploads are completed ---
  React.useEffect(() => {
    if (!formValues || !presignedPost || !areFilesUploaded) {
      return;
    }
    const sourceLanguageId = formValues[FieldNames.sourceLanguage]?.value;
    const targetLanguageIds = isTargetLanguageDisabled ?
      [ENGLISH_ID]
      : formValues[FieldNames.targetLanguages]?.map(option => option.value);
    if (!sourceLanguageId || !targetLanguageIds) {
      return;
    }
    const files: JobRequestFile[] = formValues[FieldNames.files]
      .map(file => ({
        fileName: file.name,
        category: JobRequestFileCategory.SOURCE_DOCUMENT,
        fileKey: createFileKey(presignedPost, file.name)
      }));
    submitTranslationRequest({
      requestBody: {
        requestName: createProjectName(email),
        files,
        sourceLanguageId,
        targetLanguageIds
      }
    }, {
      onSuccess: () => {
        resetPresignedPost();
        resetS3FileUpload();
        // Reset dropzone to clear files
        formikRef.current?.setValues({
          [FieldNames.sourceLanguage]: undefined,
          [FieldNames.targetLanguages]: undefined,
          [FieldNames.files]: []
        }, false);
        formikRef.current?.setFieldTouched(FieldNames.files, false);
        formikRef.current?.setFieldTouched(FieldNames.sourceLanguage, false);
        formikRef.current?.setFieldTouched(FieldNames.targetLanguages, false);
        setTimestamp(new Date().getTime());
      }
    });
  }, [areFilesUploaded, presignedPost]);

  const onSubmit = React.useCallback(async () => {
    createPresignedPost();
  }, []);

  const validate = React.useCallback(async (values: FormValues) => {
    const errors: Record<FieldNames, string> = {} as Record<FieldNames, string>;
    const sourceLanguageCode = values[FieldNames.sourceLanguage]?.value;
    const targetLanguageCodes = values[FieldNames.targetLanguages]?.map(o => o.value);
    if (sourceLanguageCode && targetLanguageCodes && targetLanguageCodes.includes(sourceLanguageCode)) {
      errors[FieldNames.targetLanguages] = t("machineTranslation.fileTranslation.validation.targetLanguages");
    }
    if (targetLanguageCodes && targetLanguageCodes.length > MAX_TARGET_LANGUAGES) {
      errors[FieldNames.targetLanguages] = t("machineTranslation.fileTranslation.validation.maxTargetLanguages");
    }
    const files = values[FieldNames.files];
    const totalFilesSize = files.reduce((prev, currentFile) => prev + currentFile.size, 0);
    if (totalFilesSize >= MAX_TOTAL_FILES_SIZE_BYTES) {
      errors[FieldNames.files] = t("machineTranslation.fileTranslation.validation.maxTotalFilesSize");
    }

    return errors;
  }, []);

  const onSourceLanguageChange = React.useCallback((sourceLanguage: SelectOption<string> | undefined) => {
    // Per LLTS-1675, if source language is non English language then target language should be English
    if (sourceLanguage?.value && sourceLanguage?.value !== ENGLISH_ID) {
      setTargetLanguageDisabled(true);
    } else {
      setTargetLanguageDisabled(false);
    }
  }, [languageOptions, setTargetLanguageDisabled]);

  React.useEffect(() => {
    if (isCreatingPresignedPost || areFilesUploading || isSubmittingTranslationRequest) {
      formikRef.current?.setSubmitting(true);
    }
  }, [isCreatingPresignedPost, areFilesUploading, isSubmittingTranslationRequest]);

  const submissionError = createPresignedPostError || filesUploadError || submitTranslationRequestError;
  React.useEffect(() => {
    if (submissionError || translationRequestSubmitted)
      formikRef.current?.setSubmitting(false);
  }, [submissionError, translationRequestSubmitted]);


  React.useEffect(() => {
    if (translationRequestSubmitted && jobRequestInfo && listRefetchRef?.current) {
      listRefetchRef.current();
    }
  }, [translationRequestSubmitted, jobRequestInfo]);

  if (mtSettingsError || lltsLanguagesError) {
    return <ErrorPage apiError={mtSettingsError || lltsLanguagesError}/>;
  }

  if (areMtSettingsLoading || areLltsLanguagesLoading || !mtSettings) {
    return <LoadingPage/>;
  }


  return (
    <>
      {submissionError &&
        <SnackbarApiError error={submissionError}/>
      }
      <Formik
        initialValues={{
          [FieldNames.files]: [],
          [FieldNames.sourceLanguage]: undefined,
          [FieldNames.targetLanguages]: undefined
        }}
        validate={validate}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({ isSubmitting }) => (
          <Form noValidate={true} autoComplete="off">
            <FormFieldObserver fieldName={FieldNames.sourceLanguage} onChange={onSourceLanguageChange}/>
            <Grid container={true} spacing={1} mt={2}>
              <Grid item={true} xs={12} md={5}>
                <SelectOneAutocompleteField
                  key={`sourceLang${timestamp}`}
                  name={FieldNames.sourceLanguage}
                  label={t("machineTranslation.sourceLanguage")}
                  size="small"
                  options={languageOptions}
                  variant="outlined"
                  required={true}
                />
              </Grid>
              <Grid item={true} xs={12} md={2} textAlign="center" order={isSmallScreen ? 4 : 2}>
                <Stack>
                  <LoadingButton isLoading={isSubmitting}>
                    {t("machineTranslation.translate")}
                  </LoadingButton>
                </Stack>
              </Grid>
              <Grid item={true} xs={12} md={5} order={isSmallScreen ? 2: 3}>
                {!isTargetLanguageDisabled &&
                  <SelectManyAutocompleteField
                    key={`targetLangs${timestamp}`}
                    name={FieldNames.targetLanguages}
                    label={t("machineTranslation.targetLanguages")}
                    options={languageOptions}
                    required={true}
                    variant="outlined"
                    size="small"
                    disabled={isTargetLanguageDisabled}
                  />
                }
                {isTargetLanguageDisabled &&
                  // Per LLTS-1675, if source language is non English language then target language should be English
                  <TextField
                    select={true}
                    label={t("machineTranslation.targetLanguages")}
                    variant="outlined"
                    size="small"
                    disabled={true}
                    fullWidth={true}
                    value="enu"
                    defaultValue="enu"
                  >
                    <option value="enu">English</option>
                  </TextField>
                }
              </Grid>
              <Grid item={true} xs={12} order={isSmallScreen ? 3 : 4}>
                <DropzoneField
                  key={`dz${timestamp}`}
                  name={FieldNames.files}
                  dropzoneText={t("machineTranslation.fileTranslation.dropZone.text")}
                  fileUploadLimits={{
                    maxFileCount: mtSettings.maxFilesPerRequest,
                    maxFileSize: mtSettings.maxFileSizeMB * 1024 * 1024,
                    acceptedFiles: SUPPORTED_FILE_TYPES
                  }}
                  required={true}
                  helperText={t("machineTranslation.fileTranslation.dropZone.helperText", {
                    maxFileSizeMB: mtSettings?.maxFileSizeMB,
                    maxFilesPerRequest: mtSettings?.maxFilesPerRequest
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} order={5}>
                <Box mt={3}>
                  <Typography
                    variant="body2"
                    sx={{ color: theme.palette.grey["700"] }}
                    dangerouslySetInnerHTML={{ __html: t("machineTranslation.disclaimer") }}
                  />
                </Box>
              </Grid>
            </Grid>

            {isSubmitting &&
              <AlertDialog title="Uploading">
                <Stack spacing={1}>
                  <Box>
                    {t("machineTranslation.fileTranslation.uploading")}
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel value={uploadProgress}/>
                  </Box>
                </Stack>
              </AlertDialog>
            }
          </Form>
        )}
      </Formik>

      <Box sx={{ mt: 8, mb: 2 }}>
        <Typography variant="h5" mb={1}>{t("machineTranslation.fileTranslation.yourRequests")}</Typography>
        <MtJobRequestList origination={JobRequestOrigination.MT} refetchRef={listRefetchRef}/>
      </Box>
    </>
  );
};

export { FileTranslationMainPage };