import * as React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import { Form, Formik } from "formik";
import { Grid } from "@mui/material";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { ReportExportType } from "pages/ReportsPage/ReportDetailsPage/ExportReportData/ExportTypes";
import { ExportReportData } from "pages/ReportsPage/ReportDetailsPage/ExportReportData/ExportReportData";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { SnackbarApiError } from "../../../components/SnackbarApiError/SnackbarApiError";
import { DataTable } from "../../../components/DataTable/DataTable";
import { DataTableColumn } from "../../../components/DataTable/types";
import { Breadcrumbs } from "../../../components/Breadcrumbs/Breadcrumbs";
import { LoadingPage } from "../../../components/LoadingPage/LoadingPage";
import { CustomFieldDefinition, ReportFilterValues, ReportMetadata, ReportsService } from "../../../gen/clients/llts";
import { sanitizeFieldName } from "../../../components/formikFields/formikUtils";
import { CustomField } from "../../../components/formikFields/CustomField/CustomField";

interface PathParams {
  reportId: string
}

function filterValuesToFiltersQueryString(report: ReportMetadata, formValues: ReportFilterValues) {
  const reportFilterValues: ReportFilterValues = {};
  report?.filters?.forEach(f => {
    const filterId = f.mapping;
    const filterValue = formValues[sanitizeFieldName(f.name)];
    if (filterValue) {
      if (f.type === CustomFieldDefinition.type.DATE)
        reportFilterValues[filterId] = Date.parse(filterValue as string);
      else
        reportFilterValues[filterId] = filterValue;
    }
  });
  return Object.keys(reportFilterValues).length ? JSON.stringify(reportFilterValues) : undefined;
}

/**
 * Parses filter's date value returned by XTRF. The value is in the format "fromDate,toDate",
 * where `fromDate` is the timestamp in milliseconds.
 * @param value
 */
function parseXtrfFromDateValue(value: string | undefined): string {
  if (!value) {
    return "";
  }
  const commaIndex = value.indexOf(",");
  const fromValue = value.substring(0, commaIndex < 0 ? undefined : commaIndex);
  const fromDate = new Date(+fromValue);
  return dayjs(fromDate).format("MM/DD/YYYY");
}

const ReportDetailsPage: React.FC = () => {
  const { reportId } = useParams<PathParams>();
  const { t } = useTranslation();
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);
  const [currentFilterValues, setCurrentFilterValues] = React.useState<ReportFilterValues>({});
  const [prevFilterValues, setPrevFilterValues] = React.useState<ReportFilterValues>({});
  const { data: report, isLoading: isReportLoading, error: reportError } = useQuery(["getReport", reportId], {
    queryFn: () => ReportsService.getReport({ reportId })
  });

  const ALL_OPTION = { label: `--- ${t("reports.details.allOption")} ---`, value: "" }

  const defaultFilterValues = React.useMemo(() => {
    if (!report) {
      return {};
    }
    const result: { [key: string]: string } = {};
    report.filters?.filter(f => f.defaultValue !== undefined && f.defaultValue !== "")
      .forEach(f => {
        if (f.type === CustomFieldDefinition.type.DATE && f.defaultValue) {
          result[sanitizeFieldName(f.name)] = parseXtrfFromDateValue(f.defaultValue);
        } else {
          result[sanitizeFieldName(f.name)] = f.defaultValue || "";
        }
        return result;
      });
    return result;
  }, [report]);

  const {
    data: reportRows,
    isLoading: areReportRowsLoading,
    error: reportRowsError,
    refetch: refetchReportRows
  } = useQuery(["getReportRows", reportId, page, rowsPerPage, defaultFilterValues], {
    queryFn: () => ReportsService.getReportRows({
      reportId,
      filters: report ? filterValuesToFiltersQueryString(report, Object.keys(currentFilterValues).length ? currentFilterValues : defaultFilterValues) : undefined,
      page: page + 1, limit: rowsPerPage
    })
  });

  const breadcrumbs = React.useMemo(() => (
    [
      {
        label: t("crumbText.home"),
        path: "/",
        active: false
      },
      {
        label: t("reports.title"),
        path: "/reports",
        active: false
      },
      {
        label: report?.name || "",
        path: `/reports/${report?.id}`,
        active: true
      },
    ]
  ), [report]);

  const columns = React.useMemo<DataTableColumn[]>(() => {
    if (!report) {
      return [];
    }
    return report.columns.map(c => ({
      id: c.columnId,
      title: c.name,
      cell: row => row[c.columnId]
    }));
  }, [report]);

  const filters = React.useMemo(() => {
    if (!report?.filters) {
      return undefined;
    }
    return report.filters.map(f => {
      if (f.options) {
        return {
          ...f,
          options: [ALL_OPTION, ...f.options]
        };
      }
      return f;
    });
  }, [report]);

  const onPageChange = React.useCallback((event, selectedPage: number) => {
    setPage(selectedPage);
  }, []);

  const onRowsPerPageChange = React.useCallback((event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
  }, []);

  // eslint-disable-next-line no-underscore-dangle
  const rowKey = React.useCallback(row => row._id, []);

  const error = reportError || reportRowsError;

  const onSubmit = React.useCallback(async () => {
    setPage(0);
    setPrevFilterValues(currentFilterValues);
    await refetchReportRows();
  }, [report, currentFilterValues, refetchReportRows]);

  if (isReportLoading) {
    return <LoadingPage/>;
  }

  const initValues: ReportFilterValues = {};
  report?.filters?.forEach(f => {
    if (f.defaultValue) {
      initValues[sanitizeFieldName(f.name)] = f.type === CustomFieldDefinition.type.DATE ?
        parseXtrfFromDateValue(f.defaultValue)
        : f.defaultValue;
  }
});

  return (
    <Container maxWidth="xl" sx={{ my: 4 }}>
      {error &&
        <SnackbarApiError error={error}/>
      }
      <Breadcrumbs breadcrumbs={breadcrumbs}/>
      <Typography variant="h5" sx={{ my: 2 }}>{report?.name}</Typography>

      {report?.filters && report.filters.length > 0 &&
        <Formik
          initialValues={initValues}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values }) => {
            setCurrentFilterValues(values);
            return (
              <Form noValidate={true}>
                <Card sx={{ p: 2 }} elevation={0}>
                  <Stack spacing={1} alignItems="center">
                    <Grid container columnSpacing={{ xs: 5, sm: 5, md: 5 }} rowSpacing={2} mb={2}>
                      {filters?.map(f => (
                        <Grid key={f.mapping} item xs={12} sm={6} lg={4}>
                          <CustomField customFieldDefinition={f}/>
                        </Grid>
                      ))
                      }
                    </Grid>
                    <LoadingButton isLoading={isSubmitting}>{t("reports.details.search")}</LoadingButton>
                  </Stack>
                </Card>
              </Form>
            );
          }}
        </Formik>
      }
      <Box textAlign="right">
        {report && reportRows?.totalRows !== undefined && reportRows.totalRows > 0 &&
          <ExportReportData
            report={report}
            exportType={ReportExportType.CSV}
            filters={report ? filterValuesToFiltersQueryString(report, prevFilterValues) : undefined}
          />
        }
      </Box>
      <DataTable
        columns={columns}
        data={reportRows?.items || []}
        rowKey={rowKey}
        isLoading={areReportRowsLoading}
        dense={false}
      />
      {reportRows?.totalRows !== undefined && reportRows?.totalRows > 0 &&
        <TablePagination
          component="div"
          count={reportRows.totalRows}
          page={page}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={onRowsPerPageChange}
          showFirstButton={true}
          showLastButton={true}
        />
      }
    </Container>    
  );
};

export { ReportDetailsPage };
