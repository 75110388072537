import * as React from "react";
import { CheckboxWithLabel, CheckboxWithLabelProps } from "formik-mui";
import { Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { FormHelperText } from "@mui/material";
import Stack from "@mui/material/Stack";

interface Props extends Omit<CheckboxWithLabelProps, "field" | "form" | "meta"> {
  name: string;
}

const CheckboxField: React.FC<Props> = ({ name, required, ...props }) => {
  const { t } = useTranslation();
  const { errors } = useFormikContext<Record<string, boolean>>();

  const validate = React.useCallback((value: string | undefined) => {
    if (required && !value) {
      return t("common.validation.required");
    }
    return undefined;
  }, [required]);

  return (
    <Stack flexGrow={0}>
      <Field
        name={name}
        type="checkbox"
        component={CheckboxWithLabel}
        validate={validate}
        {...props}
      />
      <FormHelperText margin="dense" required={required} error={!!errors[name]}>{errors[name]}</FormHelperText>
    </Stack>
  );
};

export { CheckboxField };
