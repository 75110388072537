import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { SnackbarApiError } from "components/SnackbarApiError/SnackbarApiError";
import DialogActions from "@mui/material/DialogActions";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "react-query";
import { useTranslation } from "react-i18next";
import { JobRequestsService } from "gen/clients/llts";

interface Props {
  jobRequestId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const RejectAutoQuoteDialog: React.FC<Props> = ({ jobRequestId, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const [rejectionReason, setRejectionReason] = React.useState<string>();
  const { mutate, isLoading, error } = useMutation(JobRequestsService.rejectAutoQuote);

  const onRejectQuoteClick = () => {
    mutate({
      jobRequestId,
      requestBody: {
        rejectionReason: rejectionReason?.trim() || undefined
      }
    }, { onSuccess });
  }

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle>Reject Quote</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          <Typography variant="body1">
            Please provide the reason for rejecting the quote:
          </Typography>
          <TextField
            variant="outlined"
            multiline={true}
            fullWidth={true}
            rows={5}
            onChange={e => setRejectionReason(e.target.value)}
          />
        </Stack>
        {!!error &&
          <SnackbarApiError error={error}/>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("common.cancel")}</Button>
        <LoadingButton
          color="primary"
          type="button"
          variant="contained"
          isLoading={isLoading}
          onClick={onRejectQuoteClick}
        >
          Reject Quote
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export {
  RejectAutoQuoteDialog
};
