import * as React from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { Field, useFormikContext } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import { useTranslation } from "react-i18next";
import styles from "./DropzoneField.module.scss";
import { FileUploadLimits } from "./FileUploadLimits";

interface Props {
  name: string;
  dropzoneText?: string;
  helperText?: string;
  fileUploadLimits?: FileUploadLimits;
  required?: boolean;
}

const DropzoneField: React.FC<Props> = (
  { name, dropzoneText, helperText, required, fileUploadLimits }) => {
  const { t } = useTranslation();
  const {
    isSubmitting,
    setFieldTouched,
    setFieldValue,
    validateField,
    touched,
    errors
  } = useFormikContext<Record<string, unknown>>();
  const [isInitialValidation, setIsInitialValidation] = React.useState(true);

  const validate = React.useCallback((value: File[]): string | undefined => {
    if (!isInitialValidation) {
      if (required && (value || []).length === 0) {
        return t("common.validation.required");
      }
      const uniqueFileNames = new Set<string>();
      value.forEach(f => uniqueFileNames.add(f.name));
      if (uniqueFileNames.size !== value.length) {
        return t("components.dropzoneField.duplicateFilesNotAllowed");
      }
      if(fileUploadLimits?.maxTotalSize){
        const totalSize = value.reduce((acc, f) => acc + f.size, 0);
        if(totalSize > fileUploadLimits.maxTotalSize){
          return t("components.dropzoneField.totalFileSizeExceeded", {maxTotalSizeMb: fileUploadLimits.maxTotalSize / (1024 * 1024)});
        }
      }
    }
    setIsInitialValidation(false);
    return undefined;
  }, [isInitialValidation]);

  return (
    <div>
      <Field
        name={name}
        validate={validate}
        render={() => (
          <>
            <DropzoneArea
              onChange={(updatedFiles) => {
                if (!isInitialValidation) {
                  setFieldTouched(name);
                }
                setFieldValue(name, updatedFiles);
                window.setTimeout(() => validateField(name), 0);
              }}
              filesLimit={fileUploadLimits?.maxFileCount}
              maxFileSize={fileUploadLimits?.maxFileSize}
              dropzoneText={dropzoneText}
              showFileNames={true}
              showPreviews={false}
              showFileNamesInPreview={true}
              useChipsForPreview={true}
              dropzoneClass={styles.dropzone}
              dropzoneProps={{
                disabled: isSubmitting
              }}
              showAlerts={["error"]}
              acceptedFiles={fileUploadLimits?.acceptedFiles}
            />
            <FormHelperText error={touched[name] && !!errors[name]} id={name}>
              {(touched[name] && errors[name]) || helperText}
            </FormHelperText>
          </>
        )}
      />
    </div>
  );
};

export { DropzoneField };
