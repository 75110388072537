import * as React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ApiError } from "../../gen/clients/llts";

interface Props {
  apiError: unknown
}

const parseApiErrorMessage = (apiError: unknown, unexpectedErrorMessage: string): string => {
  if (apiError instanceof ApiError) {
    if (apiError.body?.message) {
      return apiError.body?.errorCode ? `${apiError.body?.message} [Error code: ${apiError.body?.errorCode}]` : apiError.body?.message
    }
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return apiError?.message || unexpectedErrorMessage;
}

const ApiErrorMessage: React.FC<Props> = ({ apiError }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ color: "error.main" }}>{parseApiErrorMessage(apiError, t("common.unexpectedError"))}</Box>
  );
}


export { ApiErrorMessage, parseApiErrorMessage };
