import * as React from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik/dist/types";
import { useQuery } from "react-query";
import { SelectOption } from "@mui/base";
import { Alert, Box, Container, CssBaseline, Grid } from "@mui/material";
import { Formik } from "formik";
import { sanitizeFieldName } from "components/formikFields/formikUtils";
import { EmailVerificationDialog } from "components/EmailVerificationDialog/EmailVerificationDialog";
import { useDialogState } from "hooks/useDialogState/useDialogState";
import { DEFAULT_COUNTRY_CODE } from "utils/constants";
import {
  Address,
  ApiError,
  ClientConfig,
  ClientConfigsService, CreateProjectRequest, CreateQuoteRequest,
  CustomFieldDefinition,
  CustomFieldDefinitionCategory,
  CustomFieldValue,
  FileCategory,
  FileLink,
  ProjectSubmissionMode
} from "../../../../gen/clients/llts";
import { useCreateProject } from "../../hooks/useCreateProject";
import { ContentInputMethod, CreateProjectForm, FieldNames, SubmitType } from "../CreateProjectForm/CreateProjectForm";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import { ErrorPage } from "../../../../components/ErrorPage/ErrorPage";
import { FormHeader } from "../FormHeader/FormHeader";
import { SnackbarApiError } from "../../../../components/SnackbarApiError/SnackbarApiError";
import { validateEmailFormat } from "../../../../utils/stringUtils";
import { RequestSubmitted } from "../RequestSubmitted/RequestSubmitted";
import { CreateProjectFormValidator } from "../CreateProjectForm/CreateProjectFormValidator";
import { createProjectName } from "../../utils/projectUtils";
import { UserContactSectionField } from "../UserContactSection/UserContactSection";
import { ProjectInfoFieldName } from "../ProjectInfoSection/ProjectInfoSection";
import { FilesSectionFieldNames } from "../FilesSection/FilesSection";

interface Props {
  clientConfig: ClientConfig;
  email?: string;
}

type Values = Record<string, unknown>;

interface SignUpData {
  clientId: string;
  email: string;
  firstName: string;
  lastName: string;
  mailingAddress: Address;
}

const UnknownUserCreateProjectForm: React.FC<Props> = ({ clientConfig, email }) => {
  const { t } = useTranslation();
  const [
    isEmailVerificationDialogOpen,
    openEmailVerificationDialog,
    closeEmailVerificationDialog
  ] = useDialogState();
  const [requestSubmitted, setRequestSubmitted] = React.useState(false);
  const [submitType, setSubmitType] = React.useState<SubmitType>(SubmitType.PROJECT);
  const [signUpData, setSignUpData] = React.useState<SignUpData>();
  const formikRef = React.useRef<FormikProps<Values>>(null);

  const { createProject, createQuote, isInProgress, createProjectError, createQuoteError, submissionProgress } = useCreateProject({
    formRef: formikRef,
    onSuccess: () => setRequestSubmitted(true)
  });

  const clientId = clientConfig.clientId;
  const {
    data: customFieldDefinitions,
    isLoading: areCustomFieldDefinitionsLoading,
    error: customFieldDefinitionsError
  } =
    useQuery(["listCustomFieldDefinitions", clientId], {
      enabled: !!clientId,
      queryFn: () => ClientConfigsService.getCustomFieldDefinitions(
        { clientId }
      )
    });
  const projectCustomFieldDefinitions = React.useMemo(() => customFieldDefinitions?.filter(
      cfd => cfd.category === CustomFieldDefinitionCategory.PROJECT || cfd.category === undefined)
    , [customFieldDefinitions]);

  const userCustomFieldDefinitions = React.useMemo(() => customFieldDefinitions?.filter(
      cfd => cfd.category === CustomFieldDefinitionCategory.USER)
    , [customFieldDefinitions]);

  React.useEffect(() => {
    const form = formikRef.current;
    if (form) {
      form.setSubmitting(isInProgress);
    }
  }, [isInProgress]);

  const onSubmit = React.useCallback(() => {
    openEmailVerificationDialog();
  }, [openEmailVerificationDialog]);

  const onEmailVerificationCancel = React.useCallback(() => {
    formikRef.current?.setSubmitting(false);
    closeEmailVerificationDialog();
  }, [closeEmailVerificationDialog]);

  const onEmailVerificationSuccess = React.useCallback((verificationCode: string) => {
    /* eslint-disable */
    const formValues: any = formikRef.current?.values;
    closeEmailVerificationDialog();

    const projectCustomFieldValues: CustomFieldValue[] | undefined = projectCustomFieldDefinitions?.map(fieldDefinition => ({
      fieldName: fieldDefinition.name,
      mapping: fieldDefinition.mapping,
      value: formValues[sanitizeFieldName(fieldDefinition.name)]
    }));
    const userCustomFieldValues: CustomFieldValue[] | undefined = userCustomFieldDefinitions?.map(fieldDefinition => ({
      fieldName: fieldDefinition.name,
      mapping: fieldDefinition.mapping || "",
      value: formValues[sanitizeFieldName(fieldDefinition.name)]
    }));
    const requestBody: CreateProjectRequest | CreateQuoteRequest = {
      clientId,
      projectName: formValues[ProjectInfoFieldName.projectName],
      contactInfo: {
        firstName: formValues[UserContactSectionField.firstName],
        lastName: formValues[UserContactSectionField.lastName],
        email: formValues[UserContactSectionField.email],
        phoneNumber: formValues[UserContactSectionField.phone],
        address1: formValues[UserContactSectionField.address1],
        address2: formValues[UserContactSectionField.address2],
        city: formValues[UserContactSectionField.city],
        province: formValues[UserContactSectionField.province] ? +formValues[UserContactSectionField.province] : undefined,
        zipCode: formValues[UserContactSectionField.zip],
        country: formValues[UserContactSectionField.country] ? +formValues[UserContactSectionField.country] : undefined,
        customFields: userCustomFieldValues
      },
      notes: formValues[ProjectInfoFieldName.notes],
      sourceLanguageId: formValues[ProjectInfoFieldName.sourceLanguage].value,
      targetLanguageIds: formValues[ProjectInfoFieldName.targetLanguages].map((option: SelectOption<number>) => option.value),
      xtrfServiceId: formValues[ProjectInfoFieldName.xtrfServiceId] ?
        +formValues[ProjectInfoFieldName.xtrfServiceId] : undefined,
      customFields: projectCustomFieldValues,
      emailVerificationCode: verificationCode,
      fileLinks: formValues[FilesSectionFieldNames.inputMethod] === ContentInputMethod.FILELINKS ?
        (formValues[FilesSectionFieldNames.fileLinks] as FileLink[]).map(link => ({
          ...link,
          category: FileCategory.SOURCE_DOCUMENT
        })) : 
        undefined
    };
    setSignUpData({
      clientId,
      firstName: formValues[UserContactSectionField.firstName],
      lastName: formValues[UserContactSectionField.lastName],
      email: formValues[UserContactSectionField.email],
      mailingAddress: {
        address1: formValues[UserContactSectionField.address1],
        address2: formValues[UserContactSectionField.address2],
        country: {
          name: "",
          id: +(formValues[UserContactSectionField.country] || DEFAULT_COUNTRY_CODE)
        },
        province: {
          name: "",
          id: +formValues[UserContactSectionField.province]
        },
        city: formValues[UserContactSectionField.city],
        zipCode: formValues[UserContactSectionField.zip]
      }
    });

    if (formValues[FieldNames.submitType] === SubmitType.ESTIMATE) {
      setSubmitType(SubmitType.ESTIMATE);
      createQuote({ requestBody });
    } else {
      setSubmitType(SubmitType.PROJECT);
      createProject({ requestBody });
    }
  }, [projectCustomFieldDefinitions, userCustomFieldDefinitions]);

  const validate = React.useCallback((values: Values) => {
    const errors: Partial<Values> = {};
    // Validate email
    const emailValue = values.email as string;
    if (!emailValue) {
      errors.email = t("common.validation.required");
    } else if (!validateEmailFormat(emailValue)) {
      errors.email = t("createProject.unknownUser.validationMessages.emailFormat");
    } else if (clientConfig?.allowedEmailDomains && !clientConfig.allowedEmailDomains.includes(emailValue.substring(emailValue.indexOf("@") + 1))) {
      errors.email = t("createProject.unknownUser.validationMessages.emailNotAllowed");
    }
    const documentInputErrors = CreateProjectFormValidator.validate(values, t);
    return { ...errors, ...documentInputErrors };
  }, [clientConfig]);

  const initialValues = React.useMemo(() => {
    const standardFieldValues = {
      [UserContactSectionField.email]: email || "",
      [UserContactSectionField.firstName]: "",
      [UserContactSectionField.lastName]: "",
      [UserContactSectionField.address1]: "",
      [UserContactSectionField.address2]: "",
      [UserContactSectionField.city]: "",
      [UserContactSectionField.province]: "",
      [UserContactSectionField.zip]: "",
      [UserContactSectionField.country]: DEFAULT_COUNTRY_CODE,
      [UserContactSectionField.phone]: "",
      [ProjectInfoFieldName.sourceLanguage]: null,
      [ProjectInfoFieldName.targetLanguages]: [],
      [ProjectInfoFieldName.notes]: "",
      [FilesSectionFieldNames.inputMethod]: ContentInputMethod.FILES,
      [FilesSectionFieldNames.files]: "",
      [FilesSectionFieldNames.textInput]: "",
      [FilesSectionFieldNames.includeReferenceFiles]: [],
      [FilesSectionFieldNames.referenceFiles]: "",
      [ProjectInfoFieldName.projectName]: createProjectName(email),
      [FieldNames.submitType]: SubmitType.PROJECT
    };
    const customFieldValues: Record<string, string | string[]> = {};
    customFieldDefinitions?.forEach((customFieldDefinition: CustomFieldDefinition) => {
      customFieldValues[sanitizeFieldName(customFieldDefinition.name)] = customFieldDefinition.defaultValue || "";
    })
    return {
      ...standardFieldValues,
      ...customFieldValues
    }
  }, [clientConfig, customFieldDefinitions]);

  if (areCustomFieldDefinitionsLoading) {
    return <LoadingPage/>;
  }
  if (customFieldDefinitionsError) {
    return <ErrorPage apiError={customFieldDefinitionsError as ApiError}/>;
  }
  if(requestSubmitted){
    return <RequestSubmitted
      submitType={submitType}
      signUpData={signUpData}
      bannerUrl={clientConfig?.bannerUrl}
    />;
  }

  return (
    <>
      <CssBaseline/>
      <FormHeader
        bannerUrl={clientConfig?.bannerUrl}
        title={t("createProject.unknownUser.header.title")}
      >
        <p>
          {t("createProject.unknownUser.header.content1")}
        </p>
        {/* eslint-disable-next-line react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: t("createProject.unknownUser.header.content2") }}/>
      </FormHeader>
      <Container maxWidth="md">
        {clientConfig.instructionalText &&
          <Box sx={{ mb: 2 }}>
            <Alert severity="info">{clientConfig.instructionalText.split("\n").map(line => <p>{line}</p>)}</Alert>
          </Box>
        }
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <>
            {clientConfig &&
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validate={validate}
                validateOnMount={false}
                validateOnChange={false}
                validateOnBlur={true}
                innerRef={formikRef}
              >
                <CreateProjectForm
                  clientId={clientId}
                  projectCustomFieldDefinitions={projectCustomFieldDefinitions}
                  userCustomFieldDefinitions={userCustomFieldDefinitions}
                  workflowOptions={clientConfig.selectableWorkflows}
                  hideSubmitForEstimation={clientConfig.projectSubmissionMode === ProjectSubmissionMode.SUBMIT_FOR_TRANSLATION}
                  hideSubmitForTranslation={clientConfig.projectSubmissionMode === ProjectSubmissionMode.SUBMIT_FOR_ESTIMATION}
                  footerMessage={clientConfig.projectSubmissionFooterMessage}
                  submissionProgress={submissionProgress}
                />
              </Formik>
            }
            {isEmailVerificationDialogOpen &&
              <EmailVerificationDialog
                email={formikRef?.current?.values[UserContactSectionField.email] as string || ""}
                onSuccess={onEmailVerificationSuccess}
                onClose={onEmailVerificationCancel}
              />
            }
            </>
          </Grid>
        </Grid>
        {createProjectError &&
          <SnackbarApiError error={createProjectError}/>
        }
        {createQuoteError &&
          <SnackbarApiError error={createQuoteError}/>
        }
      </Container>
    </>
  );
};

export { UnknownUserCreateProjectForm };
