import * as React from "react";
import { Box, FormControlLabel, Radio } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { CheckboxWithLabel, RadioGroup } from "formik-mui";
import { DropzoneField } from "components/formikFields/DropzoneField/DropzoneField";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { useTranslation } from "react-i18next";
import { FileLink } from "gen/clients/llts/models/FileLink";
import FileLinksField from "../FileLinksField/FileLinksField";
import { FileUploadLimits } from "../../../../components/formikFields/DropzoneField/FileUploadLimits";

const MAX_FILE_SIZE = 1000 * 1024 * 1024;

const DEFAULT_FILE_UPLOAD_LIMITS: FileUploadLimits = { maxFileCount: 100, maxFileSize: MAX_FILE_SIZE };

interface Props {
  fileUploadLimits?: FileUploadLimits;
}

export enum FilesSectionFieldNames {
  inputMethod = "inputMethod",
  files = "files",
  textInput = "textInput",
  includeReferenceFiles = "includeReferenceFiles",
  referenceFiles = "referenceFiles",
  fileLinks = "fileLinks"
}

export enum ContentInputMethod {
  FILES = "FILES",
  TEXT = "TEXT",
  FILELINKS = "FILELINKS"
}

export interface FilesSectionFormValues {
  [FilesSectionFieldNames.files]?: File[];
  [FilesSectionFieldNames.referenceFiles]?: File[];
  [FilesSectionFieldNames.inputMethod]: ContentInputMethod;
  [FilesSectionFieldNames.textInput]: string;
  [FilesSectionFieldNames.includeReferenceFiles]: string[];
  [FilesSectionFieldNames.fileLinks]: FileLink[];
}

const FilesSection: React.FC<Props> = ({ fileUploadLimits = DEFAULT_FILE_UPLOAD_LIMITS }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<FilesSectionFormValues>();
  const dropzoneHelperText = React.useMemo(() =>
      fileUploadLimits?.maxTotalSize ?
        t("createProject.createProjectForm.filesDropzone.helperTextWithMaxTotalSize", {
          maxFileCount: fileUploadLimits.maxFileCount,
          maxFileSizeMb: (fileUploadLimits.maxFileSize || 0) / (1024 * 1024),
          maxTotalSizeMb: fileUploadLimits.maxTotalSize / (1024 * 1024)
        })
        : t("createProject.createProjectForm.filesDropzone.helperText", {
          maxFileCount: fileUploadLimits.maxFileCount,
          maxFileSizeMb: (fileUploadLimits.maxFileSize || 0) / (1024 * 1024)
        })
    , [fileUploadLimits])
  return (
    <>
      <Box>
        <Field
          component={RadioGroup}
          row={true}
          name={FilesSectionFieldNames.inputMethod}
        >
          <FormControlLabel
            value={ContentInputMethod.FILES}
            control={<Radio/>}
            label={t("createProject.createProjectForm.inputMethod.files") as string}
          />
          <FormControlLabel
            value={ContentInputMethod.TEXT}
            control={<Radio/>}
            label={t("createProject.createProjectForm.inputMethod.text") as string}
          />
          <FormControlLabel
            value={ContentInputMethod.FILELINKS}
            control={<Radio/>}
            label={t("createProject.createProjectForm.inputMethod.fileLinks") as string}
          />
        </Field>
      </Box>
      {/* Files upload section */}
      {values[FilesSectionFieldNames.inputMethod] === ContentInputMethod.FILES &&
        <>
          <Box marginY={3}>{t("createProject.createProjectForm.addFiles")}</Box>
          <DropzoneField
            name={FilesSectionFieldNames.files}
            fileUploadLimits={fileUploadLimits}
            dropzoneText={t("createProject.createProjectForm.filesDropzone.message")}
            helperText={dropzoneHelperText}
          />
          <Box>
            <Field
              name={FilesSectionFieldNames.includeReferenceFiles}
              type="checkbox"
              component={CheckboxWithLabel}
              value="true"
              Label={{ label: t("createProject.createProjectForm.uploadReferenceFiles") }}
            />
            {values[FilesSectionFieldNames.includeReferenceFiles].length > 0 &&
              <Box>
                <DropzoneField
                  name={FilesSectionFieldNames.referenceFiles}
                  fileUploadLimits={fileUploadLimits}
                  dropzoneText={t("createProject.createProjectForm.referenceFileDropzone.message")}
                  helperText={dropzoneHelperText}
                />
              </Box>
            }
          </Box>
        </>
      }
      {values[FilesSectionFieldNames.inputMethod] === ContentInputMethod.TEXT &&
        <>
          <TextInputField
            name={FilesSectionFieldNames.textInput}
            label={t("createProject.createProjectForm.enterText")}
            multiline={true}
            rows={10}
            variant="outlined"
          />
        </>
      }
      {/* Filelinks section */}
      {values[FilesSectionFieldNames.inputMethod] === ContentInputMethod.FILELINKS &&
        <>
          <Box marginY={3}>{t("createProject.createProjectForm.addFileLinks")}</Box>
          <FileLinksField name={FilesSectionFieldNames.fileLinks}/>
        </>
      }
    </>
  );
};

export { FilesSection };
