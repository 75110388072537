import * as React from "react";
import { useFormikContext } from "formik";

export const ScrollToFieldError: React.FC = () => {
  const { submitCount, isValid, errors } = useFormikContext()

  React.useEffect(() => {
    if (isValid) return

    const fieldErrorNames = Object.keys(errors);
    if (fieldErrorNames.length <= 0) return

    let element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    )
    if (!element) {
      element = document.getElementById(fieldErrorNames[0]);
    }
    if (!element) return

    // Scroll to first known error into view
    element.scrollIntoView({ behavior: "smooth", block: "center" })
  }, [submitCount]);

  return null
}