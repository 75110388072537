import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Typography
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { useCountryOptions } from "hooks/useCountryOptions/useCountryOptions";
import { useProvinceOptions } from "hooks/useProvinceOptions/useProvinceOptions";
import { FormFieldObserver } from "components/formikFields/FormFieldObserver/FormFieldObserver";
import { Address } from "gen/clients/llts/models/Address";
import { DEFAULT_COUNTRY_CODE } from "utils/constants";
import { SnackbarApiError } from "../../../../components/SnackbarApiError/SnackbarApiError";
import { LoadingButton } from "../../../../components/LoadingButton/LoadingButton";

enum FieldNames {
  addressLine1 = "addressLine1",
  addressLine2 = "addressLine2",
  city = "city",
  country = "country",
  province = "province",
  zip = "zip"
}

export interface FormValues {
  [FieldNames.addressLine1]: string;
  [FieldNames.addressLine2]: string;
  [FieldNames.city]: string;
  [FieldNames.zip]: string;
  [FieldNames.province]: string;
  [FieldNames.country]: string;
}

interface Props {
  logoUrl?: string;
  onSuccess: (signUpData: Address) => void;
  onCancel: () => void;
  isLoading: boolean;
  error: unknown;
}

const UserAddressInput: React.FC<Props> = ({
  logoUrl,
  onSuccess,
  onCancel,
  isLoading,
  error
}) => {
  const { t } = useTranslation();
  const [countryValue, setCountryValue] = React.useState<number>(
    +DEFAULT_COUNTRY_CODE
  );
  const { countryOptions, isCountriesLoading, countriesError } =
    useCountryOptions();
  const { provinceOptions, isProvincesLoading, provincesError } =
    useProvinceOptions(countryValue);

  const onSubmit = React.useCallback(
    (values: FormValues) => {
      const province = values[FieldNames.province] ?
        {
          name: (provinceOptions.find(o => o.value === +values[FieldNames.province])?.label as string) || "",
          id: +values[FieldNames.province]
        }
        : undefined;
      onSuccess({
        province,
        country: {
          name: (countryOptions.find(o => o.value === +values[FieldNames.country])?.label as string) || "",
          id: +values[FieldNames.country]
        },
        city: values[FieldNames.city].trim() || undefined,
        address1: values[FieldNames.addressLine1].trim() || undefined,
        address2: values[FieldNames.addressLine2].trim() || undefined,
        zipCode: values[FieldNames.zip].trim() || undefined
      });
    },
    [onSuccess]
  );

  const validate = React.useCallback((values) => {
    const errors: Record<string, string> = {};
    if (!values[FieldNames.addressLine1].trim()) {
      errors[FieldNames.addressLine1] = t("common.validation.required");
    }
    if (!values[FieldNames.city].trim()) {
      errors[FieldNames.city] = t("common.validation.required");
    }
    if (!values[FieldNames.zip].trim()) {
      errors[FieldNames.zip] = t("common.validation.required");
    }
    if (!values[FieldNames.country]) {
      errors[FieldNames.country] = t("common.validation.required");
    }
    return errors;
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {error && <SnackbarApiError error={error} />}
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {logoUrl && <img src={logoUrl} alt={t("signUp.logo.alt")} />}
        {!logoUrl && (
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
        )}
        <Typography component="h1" variant="h5">
          {t("signUp.signUp")}
        </Typography>
        <Typography component="h1" variant="h6" sx={{ my: 3 }}>
          {t("signUp.provideAddress")}
        </Typography>        
        <Formik
          initialValues={{
            [FieldNames.city]: "",
            [FieldNames.addressLine1]: "",
            [FieldNames.addressLine2]: "",
            [FieldNames.zip]: "",
            [FieldNames.country]: DEFAULT_COUNTRY_CODE,
            [FieldNames.province]: ""
          }}
          onSubmit={onSubmit}
          validate={validate}
          validateOnMount={false}
        >
          <Box sx={{ mt: 3 }}>
            <Form noValidate={true}>
              <FormFieldObserver
                fieldName={FieldNames.country}
                onChange={(value) => setCountryValue(+value)}
              />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    name={FieldNames.addressLine1}
                    component={TextField}
                    placeholder={t("signUp.addressLine1")}
                    label={t("signUp.addressLine1")}
                    variant="outlined"
                    size="medium"
                    fullWidth
                    required
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={FieldNames.addressLine2}
                    component={TextField}
                    placeholder={t("signUp.addressLine2")}
                    label={t("signUp.addressLine2")}
                    variant="outlined"
                    size="medium"
                    fullWidth
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={FieldNames.city}
                    component={TextField}
                    placeholder={t("signUp.city")}
                    label={t("signUp.city")}
                    variant="outlined"
                    size="medium"
                    fullWidth
                    required
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={7}>
                  <SelectOneField
                    name={FieldNames.province}
                    label={t("signUp.province")}
                    variant="outlined"
                    options={provinceOptions}
                    isLoading={isProvincesLoading}
                    isError={!!provincesError}
                  />
                </Grid>
                <Grid item xs={5}>
                  <Field
                    name={FieldNames.zip}
                    component={TextField}
                    placeholder={t("signUp.zip")}
                    label={t("signUp.zip")}
                    variant="outlined"
                    size="medium"
                    fullWidth
                    required
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectOneField
                    name={FieldNames.country}
                    label={t("signUp.country")}
                    variant="outlined"
                    options={countryOptions}
                    isLoading={isCountriesLoading}
                    isError={!!countriesError}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={1} sx={{ mt: 5, mb: 2 }}>
                <Grid item={true} xs={6}>
                  <Button
                    fullWidth
                    variant="text"
                    color="secondary"
                    onClick={onCancel}
                  >
                    {t("common.cancel")}
                  </Button>
                </Grid>
                <Grid item={true} xs={6}>
                  <LoadingButton
                    isLoading={isLoading}
                    fullWidth
                    variant="contained"
                  >
                    {t("common.continue")}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </Box>
        </Formik>
      </Box>
    </Container>
  );
};

export { UserAddressInput };
