import * as React from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  CardHeader,
  Box
} from "@mui/material";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { formatIso } from "utils";
import { useTranslation } from "react-i18next";
import { StatisticsService } from "../../gen/clients/llts";
import HeaderRow from "./components/HeaderRow/HeaderRow";
import HomeCrumb from "../../components/Breadcrumb/HomeCrumb";
import IDashBoardDetailPage from "../../interfaces/dashboardPage";

dayjs.extend(duration);

const FohDetailsPage: React.FC<IDashBoardDetailPage> = (props) => {
  const {resourceKey} = props;
  // eslint-disable-next-line react/destructuring-assignment
  const reportType = props.id;

  const isCompletedReport = reportType === "completedQuotesStats";

  const {id} = useParams<{ id: string }>();
  const {name} = useParams<{ name: string }>();
  const isUnassignedReport = id === "null"
  const {t} = useTranslation();

  const {
    data: theQuoteStats,
    isLoading,
    isError
  } = useQuery([reportType, id], {
    queryFn: () =>
      // eslint-disable-next-line no-nested-ternary
      isUnassignedReport ? StatisticsService.getProjectCoordinatorCurrentUnassignedStatistics() :
      isCompletedReport ? StatisticsService.getProjectCoordinatorCompletedStatisticsById({id}) :
        StatisticsService.getProjectCoordinatorCurrentStatisticsById({id})
  });

  if (isError) {
    return <h2>{t("common.systemError")}</h2>;
  }

  /**
   * If the start date exceed 24 hours, we need to flag it in red for higher visibility.
   * @param startDate
   */
  const insertStartDate = (startDate: string) => (
    <>
      <TableCell
        sx={
          dayjs
            .duration(dayjs().diff(startDate))
            .asHours() >= 24
            ? {
              backgroundColor: "red"
            }
            : {backgroundColor: "#f5f5f5"}
        }
      >
        {formatIso(startDate)}
      </TableCell>
    </>
  )

  /**
   * The completed report has an extra Start Date column in the 2nd column position.
   * Also, the link text to the xtrf project id between the completed and current is different
   * for some odd reason.
   */
  const generateRows = () => (
    theQuoteStats?.map((row) => (
      <TableRow
        key={row.quoteId}
        sx={{
          "&:last-child td, &:last-child th": {border: 0}
        }}
      >
        <TableCell component="th" scope="row">
          <a
            href={`${process.env.REACT_APP_XTRF_HOME}/xtrf/faces/quote/form.seam?id=${row.quoteId}`}
            target="_blank"
            rel="noreferrer"
          >
            {isCompletedReport ? row.quoteId : row.internalProjectId}
          </a>
        </TableCell>
        {isCompletedReport && (
          insertStartDate(row.startDate)
        )}
        <TableCell>
          {row.projectDeadline?.slice(0, 10)}
        </TableCell>
        <TableCell>{row.projectName}</TableCell>
        <TableCell>{row.client}</TableCell>
        <TableCell>{row.languages}</TableCell>
        <TableCell>{row.totalAgreed}</TableCell>
        <TableCell>{row.rateType}</TableCell>
      </TableRow>
    ))
  )

  const title = isCompletedReport ? t("DASHBOARD.completedQuotes", {name}) :
    t("DASHBOARD.currentQuotes", {name});

  return (
    <>
      <div style={{marginBottom: 20}}>
        <HomeCrumb/>
      </div>
      <Box sx={{ pt: 3 }}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid>
              <Card sx={{backgroundColor: "#f5f5f5"}}>
                <CardHeader
                  title={title}
                />
                <CardContent>
                  {isLoading && <CircularProgress/>}
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <HeaderRow resourceKey={`${resourceKey}.${reportType}`} />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {generateRows()}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default FohDetailsPage;
