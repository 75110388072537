import React, { ReactElement } from "react";
import { Route, Switch } from "react-router-dom";
import ProjectManagerDetailsPage from "pages/Dashboard/ProjectManagerDetailsPage";
import DetailsPage from "pages/Dashboard/DetailsPage";
import QaSpecialistDetailsPage from "pages/Dashboard/QaSpecialistDetailsPage";
import FohDetailsPage from "pages/Dashboard/FohDetailsPage";
import { AutoQuotePage } from "pages/AutoQuotePage/AutoQuotePage";
import HomePage from "../pages/HomePage/HomePage";
import DashboardPage from "../pages/Dashboard/DashboardPage";
import ServiceInfoPage from "../pages/HomePage/SubPages/ServiceInfoPage";
import { SignUpPage } from "../pages/SignUpPage/SignUpPage";
import {
  CreateProjectFromExternalLinkPage
} from "../pages/CreateProjectPage/CreateProjectFromExternalLinkPage/CreateProjectFromExternalLinkPage";
import { CreateProjectPage } from "../pages/CreateProjectPage/CreateProjectPage";
import AppPlaceholder from "../pages/HomePage/SubPages/AppPlaceholder";
import { EmailConfirmationPage } from "../pages/EmailConfirmationPage/EmailConfirmationPage";
import IFrameViewer from "../pages/HomePage/SubPages/IFrameViewer";
import { ChangePasswordPage } from "../pages/ChangePasswordPage/ChangePasswordPage";
import { ReportsPage } from "../pages/ReportsPage/ReportsPage";
import { ReportDetailsPage } from "../pages/ReportsPage/ReportDetailsPage/ReportDetailsPage";
import {
  MachineTranslationPage,
  MachineTranslationTabId
} from "../pages/MachineTranslationPage/MachineTranslationPage";
import { RestrictedPage } from "./RestrictedPage/RestrictedPage";
import { UserGroup } from "../gen/clients/llts";
import { CoupaStarterPage } from "../pages/coupa/CoupaStarterPage";
import {
  CreateCoupaQuotePage
} from "../pages/coupa/CreateCoupaQuotePage/CreateCoupaQuotePage";
import { ErrorPage } from "./ErrorPage/ErrorPage";
import { CoupaErrorPage } from "../pages/coupa/CoupaErrorPage/CoupaErrorPage";
import { CoupaRequestsPage } from "../pages/coupa/CoupaRequestsPage/CoupaRequestsPage";
import { JobRequestDetailsPage } from "../pages/coupa/CoupaRequestsPage/JobRequestDetailsPage/JobRequestDetailsPage";

const Routes = (): ReactElement => (
  <Switch>
    <Route path="/DASHBOARD">
      <DashboardPage id="DASHBOARD"/>
    </Route>
    <Route path="/pmdetails/:name/:id">
      <ProjectManagerDetailsPage id="projectManagerDetails" resourceKey="pm"/>
    </Route>
    <Route path="/qadetails/:name/:id">
      <QaSpecialistDetailsPage id="qaSpecialistDetails" resourceKey="qa" />
    </Route>
    <Route path="/pastdue/:name/:id">
      <DetailsPage id="pastDueDetails" resourceKey="pastdue" />
    </Route>
    <Route path="/duetoday/:name/:id">
      <DetailsPage id="dueToday" resourceKey="dueToday" />
    </Route>
    <Route path="/currentquotes/:name/:id">
      <FohDetailsPage id="currentQuoteStats" resourceKey="foh" />
    </Route>
    <Route path="/completedquotes/:name/:id">
      <FohDetailsPage id="completedQuotesStats" resourceKey="foh" />
    </Route>
    <Route path="/languageLineConnect">
      <AppPlaceholder id="languageLineConnect" />
    </Route>
    <Route path="/quickTranslation">
      <AppPlaceholder id="quickTranslation" />
    </Route>
    <Route path="/connector">
      <AppPlaceholder id="connector" />
    </Route>
    <Route path="/websiteProxy">
      <AppPlaceholder id="websiteProxy" />
    </Route>
    <Route path="/consoleHelp">
      <IFrameViewer id="consoleHelp" fileName="Frequently-Asked-Questions.html" />
    </Route>
    <Route path="/languageConnect">
      <ServiceInfoPage id="languageConnect" />
    </Route>
    <Route path="/webSitesEasyWay">
      <ServiceInfoPage id="webSitesEasyWay" />
    </Route>
    <Route path="/anythingInBetween">
      <ServiceInfoPage id="anythingInBetween" />
    </Route>
    <Route path="/solutionWizard">
      <ServiceInfoPage id="solutionWizard" />
    </Route>
    <Route path="/signUp" exact={true}>
      <SignUpPage />
    </Route>
    <Route path="/signUp/:clientId/:token" exact={true}>
      <SignUpPage/>
    </Route>
    <Route path="/confirmEmail" exact={false}>
      <EmailConfirmationPage/>
    </Route>
    <Route path="/createProject/:clientId/:token">
      <CreateProjectFromExternalLinkPage />
    </Route>
    <Route path="/createProject" exact>
      <CreateProjectPage />
    </Route>
    <Route path="/changePassword" exact={true}>
      <ChangePasswordPage/>
    </Route>
    <Route path="/reports" exact={true}>
      <RestrictedPage
        allowedRoles={[UserGroup.CLIENT, UserGroup.CLIENT_EMPLOYEE]}
        requiredApplications={["reports"]}
      >
        <ReportsPage/>
      </RestrictedPage>
    </Route>
    <Route path="/reports/:reportId">
      <RestrictedPage
        allowedRoles={[UserGroup.CLIENT, UserGroup.CLIENT_EMPLOYEE]}
        requiredApplications={["reports"]}
      >
        <ReportDetailsPage/>
      </RestrictedPage>
    </Route>
    <Route path="/machineTranslation" exact={true}>
      <RestrictedPage
        allowedRoles={[UserGroup.CLIENT, UserGroup.CLIENT_EMPLOYEE]}
        requiredApplications={["machineTranslation"]}
      >
        <MachineTranslationPage/>
      </RestrictedPage>
    </Route>
    <Route path={`/machineTranslation/${MachineTranslationTabId.textTranslation}`}>
      <RestrictedPage
        allowedRoles={[UserGroup.CLIENT, UserGroup.CLIENT_EMPLOYEE]}
        requiredApplications={["machineTranslation"]}
      >
        <MachineTranslationPage selectedTabId={MachineTranslationTabId.textTranslation}/>
      </RestrictedPage>
    </Route>
    <Route path={`/machineTranslation/${MachineTranslationTabId.fileTranslation}/:jobRequestId?`}>
      <RestrictedPage
        allowedRoles={[UserGroup.CLIENT, UserGroup.CLIENT_EMPLOYEE]}
        requiredApplications={["machineTranslation"]}
      >
        <MachineTranslationPage selectedTabId={MachineTranslationTabId.fileTranslation}/>
      </RestrictedPage>
    </Route>
    <Route path="/coupa/starter/:requestId" exact={true}>
      <CoupaStarterPage/>
    </Route>
    <Route path="/coupa/createQuote" exact={true}>
      <RestrictedPage
        allowedRoles={[UserGroup.CLIENT, UserGroup.CLIENT_EMPLOYEE]}
        requiredApplications={["coupa"]}
      >
        <CreateCoupaQuotePage/>
      </RestrictedPage>
    </Route>
    <Route path="/coupa/error" exact={true}>
      <CoupaErrorPage/>
    </Route>
    <Route path="/coupa/:jobRequestId" exact={true}>
      <JobRequestDetailsPage/>
    </Route>
    <Route path="/coupa/createQuote" exact={true}>
      <ErrorPage apiError={{ message: "Invalid page URL" }}/>
    </Route>
    <Route path="/coupa" exact={false}>
      <CoupaRequestsPage/>
    </Route>
    <Route path="/quotes/:jobRequestId" exact={true}>
      <AutoQuotePage/>
    </Route>
    <Route path="/">
      <HomePage id="homePage"/>
    </Route>
  </Switch>
);

export default Routes;
