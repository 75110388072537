import * as React from "react";
import { Grid } from "@mui/material";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { CustomField } from "components/formikFields/CustomField/CustomField";
import { useTranslation } from "react-i18next";
import { CustomFieldDefinition } from "gen/clients/llts";
import { useCountryOptions } from "hooks/useCountryOptions/useCountryOptions";
import { useProvinceOptions } from "hooks/useProvinceOptions/useProvinceOptions";
import { useFormikContext } from "formik";

interface Props {
  userCustomFieldDefinitions?: CustomFieldDefinition[];
  showCompanyName?: boolean;
}

export enum UserContactSectionField {
  companyName = "companyName",
  email = "email",
  firstName = "firstName",
  lastName = "lastName",
  address1 = "address1",
  address2 = "address2",
  city = "city",
  province = "province",
  country = "country",
  zip = "zip",
  phone = "phone",
}

export interface UserContactSectionFormValues {
  [UserContactSectionField.companyName]: string;
  [UserContactSectionField.email]: string;
  [UserContactSectionField.firstName]: string;
  [UserContactSectionField.lastName]: string;
  [UserContactSectionField.address1]: string;
  [UserContactSectionField.address2]: string;
  [UserContactSectionField.city]: string;
  [UserContactSectionField.zip]: string;
  [UserContactSectionField.phone]: string;
  [UserContactSectionField.province]: string;
  [UserContactSectionField.country]: string;
}

const UserContactSection: React.FC<Props> = ({ userCustomFieldDefinitions, showCompanyName }) => {
  const { t } = useTranslation();
  const { values } = useFormikContext<UserContactSectionFormValues>();
  const { countryOptions, isCountriesLoading, countriesError } = useCountryOptions();
  const { provinceOptions, isProvincesLoading, provincesError } = 
    useProvinceOptions(values[UserContactSectionField.country] ? +values[UserContactSectionField.country] : undefined);

  return (
    <Grid container spacing={4}>
      {showCompanyName && (
        <Grid item xs={12}>
          <TextInputField
            name={UserContactSectionField.companyName}
            label={t("createProject.createProjectForm.companyName")}
            required={true}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TextInputField
          name={UserContactSectionField.email}
          label={t("createProject.createProjectForm.email")}
          type="email"
          required={true}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInputField
          name={UserContactSectionField.phone}
          label={t("createProject.createProjectForm.phone")}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInputField name={UserContactSectionField.firstName} label={t("createProject.createProjectForm.firstName")}
                        required={true}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInputField name={UserContactSectionField.lastName} label={t("createProject.createProjectForm.lastName")}
                        required={true}/>
      </Grid>
      <Grid item xs={12}>
        <TextInputField name={UserContactSectionField.address1} label={t("createProject.createProjectForm.address1")}/>
      </Grid>
      <Grid item xs={12}>
        <TextInputField name={UserContactSectionField.address2} label={t("createProject.createProjectForm.address2")}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInputField name={UserContactSectionField.city} label={t("createProject.createProjectForm.city")}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextInputField name={UserContactSectionField.zip} label={t("createProject.createProjectForm.zip")}/>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectOneField
          name={UserContactSectionField.country}
          label={t("createProject.createProjectForm.country")}
          options={countryOptions}
          isLoading={isCountriesLoading}
          isError={!!countriesError}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectOneField
          name={UserContactSectionField.province}
          label={t("createProject.createProjectForm.province")}
          options={provinceOptions}
          isLoading={isProvincesLoading}
          isError={!!provincesError}
        />
      </Grid>
      {/* User custom fields */}
      {userCustomFieldDefinitions && userCustomFieldDefinitions.map(customFieldDefinition => (
        <React.Fragment key={customFieldDefinition.name}>
          {customFieldDefinition.visible !== false &&
            <Grid item xs={12}>
              <CustomField customFieldDefinition={customFieldDefinition}/>
            </Grid>
          }
        </React.Fragment>
      ))}
    </Grid>
  );
};

export { UserContactSection };
