import * as React from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  type?: string;
  multiline?: boolean;
  rows?: number;
  maxRows?: number;
  helperText?: string;
  validationRegex?: string;
  validationMessage?: string;
  variant?: "outlined" | "filled" | "standard";
  maxLength?: number;
  autoComplete?: string;
}

const TextInputField: React.FC<Props> = (
  {
    name, label, placeholder, required, disabled, helperText,
    multiline, rows, maxRows, type,
    validationRegex, validationMessage, variant = "standard", maxLength, autoComplete
  }) => {
  const { t } = useTranslation();
  const validate = React.useCallback((value: string | undefined) => {
    if (required && !value) {
      return t("common.validation.required");
    }
    if (validationRegex && value && !value.match(new RegExp(validationRegex))) {
      return validationMessage || "Invalid format";
    }
    return undefined;
  }, [required, validationRegex, validationMessage]);
  return (
    <Field
      name={name}
      component={TextField}
      placeholder={placeholder || label}
      label={label}
      variant={variant}
      required={required}
      disabled={disabled}
      type={type}
      multiline={multiline}
      rows={rows}
      maxRows={maxRows}
      fullWidth
      validate={validate}
      helperText={helperText}
      inputProps={maxLength ? { maxLength } : undefined}
      autoComplete={autoComplete}
    />
  );
}
export { TextInputField };
