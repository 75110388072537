import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CssBaseline, Container, Alert, AlertTitle } from '@mui/material';

const AutoQuoteAcceptanceExpired: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <CssBaseline />
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 5, width: "100%" }}>
          <AlertTitle>{t("autoQuote.expired.title")}</AlertTitle>
          <p>{t("autoQuote.expired.paragraph1")}</p>
          <p>
            <RouterLink to="/">{t("autoQuote.returnHome")}</RouterLink>
          </p>
        </Alert>
      </Container>
    </div>
  );
};

export default AutoQuoteAcceptanceExpired;
