import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "usehooks-ts";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { DataTable } from "../../../components/DataTable/DataTable";
import { ApiErrorMessage } from "../../../components/ApiErrorMessage/ApiErrorMessage";
import { TokenBasedPaginator } from "../../../components/TokenBasedPaginator/TokenBasedPaginator";
import {
  JobRequestCollection,
  JobRequestOrigination,
  JobRequestsService,
  JobRequestSummary
} from "../../../gen/clients/llts";
import { formatDate } from "../../../utils/dateUtils";
import { JobRequestUtils } from "../../../utils/jobRequestUtils";
import { JobRequestState } from "../../../components/jobRequests/JobRequestState/JobRequestState";
import { COUPA_REQUEST_ID_SESSION_STORAGE_KEY } from "../../../utils/constants";

const CoupaRequestsPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [requestId] = useSessionStorage(COUPA_REQUEST_ID_SESSION_STORAGE_KEY, "");
  const [rowsPerPage, setRowsPerPage] = useSessionStorage<number>("coupaRequestList.rowsPerPage.1", 10);
  const [nextPageToken, setNextPageToken] = React.useState<string>();
  const [pageToken, setPageToken] = React.useState<string>();
  const paginatorResetRef = React.useRef<() => void>(() => {
    window.console.log("placeholder function");
  });
  React.useEffect(() => {
    paginatorResetRef?.current();
  }, [rowsPerPage]);

  const { data, isLoading, error } = useQuery(["listJobRequests", rowsPerPage, pageToken], {
    queryFn: () => JobRequestsService.listJobRequests({
      origination: JobRequestOrigination.COUPA,
      pageToken,
      pageSize: rowsPerPage
    }),
    onSuccess: (collection => {
      setNextPageToken(collection.nextPageToken);
    }),
    refetchInterval: (jrCollection: JobRequestCollection | undefined) =>
      jrCollection?.items
        .some(jr => JobRequestUtils.isInProgress(jr.status)) ? 10000 : false,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    cacheTime: 0
  });

  const rowKey = React.useCallback((jobRequest: JobRequestSummary) =>
    jobRequest.id, []);
  const onRowClick = React.useCallback((jobRequest: JobRequestSummary) => {
    history.push(`/coupa/${jobRequest.id}`);
  }, []);
  const nameCell = React.useCallback((jobRequest: JobRequestSummary) =>
    <Link
      onClick={(e) => {
        e.preventDefault();
        onRowClick(jobRequest);
      }}
      href={`/coupa/${jobRequest.id}`}>
      <Typography variant="body2">
        {jobRequest.name}
      </Typography>
    </Link>, []);
  const sourceLanguageCell = React.useCallback((jobRequest: JobRequestSummary) =>
    jobRequest.sourceLanguage?.name, []);
  const targetLanguagesCell = React.useCallback((jobRequest: JobRequestSummary) =>
    jobRequest.targetLanguages?.map(l => l.name)
      .sort((l1, l2) => l1.localeCompare(l2))
      .join(", "), []);
  const submittedOnCell = React.useCallback((jobRequest: JobRequestSummary) =>
    formatDate(jobRequest.createTime), []);
  const statusCell = React.useCallback((jobRequest: JobRequestSummary) =>
    <JobRequestState jobRequest={jobRequest} variant="body2"/>, []);

  const onRowsPerPageChange = React.useCallback((newRowsPerChange: number) => {
    setRowsPerPage(newRowsPerChange);
    setPageToken(undefined);
    setNextPageToken(undefined);
    paginatorResetRef?.current();
  }, []);

  return (
    <Container maxWidth="xl" sx={{ my: 4 }}>
      <Typography variant="h4" pb={2}>Coupa Requests</Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push("/coupa/createQuote")}
        disabled={!requestId}
        sx={{ my: 2 }}
      >
        Create Request
      </Button>
      <DataTable
        data={data?.items || []}
        columns={[
          {
            id: "name",
            title: <Typography fontWeight="bold">{t("machineTranslation.fileTranslation.requests.name")}</Typography>,
            cell: nameCell
          },
          {
            id: "sourceLanguage",
            title: <Typography
              fontWeight="bold">{t("machineTranslation.fileTranslation.requests.sourceLanguage")}</Typography>,
            cell: sourceLanguageCell
          },
          {
            id: "targetLanguage",
            title: <Typography
              fontWeight="bold">{t("machineTranslation.fileTranslation.requests.targetLanguages")}</Typography>,
            cell: targetLanguagesCell
          },
          {
            id: "submittedOn",
            title: <Typography
              fontWeight="bold">{t("machineTranslation.fileTranslation.requests.submittedOn")}</Typography>,
            cell: submittedOnCell
          },
          {
            id: "status",
            title: <Typography fontWeight="bold">{t("machineTranslation.fileTranslation.requests.status")}</Typography>,
            cell: statusCell
          },
        ]}
        rowKey={rowKey}
        error={!!error && <ApiErrorMessage apiError={error}/>}
        isLoading={isLoading}
        onRowClick={onRowClick}
        dense={true}
        rowActions={[
          {
            title: t("machineTranslation.fileTranslation.actions.viewDetails"),
            action: onRowClick,
          }
        ]}
      />
      <TokenBasedPaginator
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        onPageTokenChange={setPageToken}
        nextPageToken={nextPageToken}
        currentPageDataRows={data?.items.length}
        resetRef={paginatorResetRef}
      />
    </Container>
  );
};

export { CoupaRequestsPage };
