export const DEFAULT_COUNTRY_CODE = "227";  // USA

export const SUPPORTED_APPS = [
  "createProject",
  "xtrfDashboard",
  "invoices",
  "llsInterpretingService",
  "languageLineConnect",
  "websiteProxy",
  "connector",
  "machineTranslation",
  "quickTranslation",
  "consoleHelp",
  "reports",
  "coupa"
];

export const USER_SESSION_OVERRIDE_SESSION_STORAGE_KEY = "llts.portal.user_session_override"

export const COUPA_REQUEST_ID_SESSION_STORAGE_KEY = "llts.portal.coupa_request_id"