import * as React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Field, useFormikContext } from "formik";
import { RadioGroup } from "formik-mui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { SelectOption } from "@mui/base";
import { useTranslation } from "react-i18next";
import { FormHelperText } from "@mui/material";

interface Props {
  name: string;
  options: SelectOption<string>[];
  label?: React.ReactNode;
  row?: boolean;
  required?: boolean;
}

const RadioGroupField: React.FC<Props> = (
  { name, label, options, row, required }) => {
  const { t } = useTranslation();
  const { errors, touched, isSubmitting } = useFormikContext<Record<string, unknown>>();
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <Field
        component={RadioGroup}
        row={row}
        name={name}
        required={required}
        validate={(value: string) => required && !value ? t("common.validation.required") : undefined}
        disabled={isSubmitting}
      >
        {options.map(option => (
          <FormControlLabel
            value={option.value}
            control={<Radio/>}
            label={(option.label as string | undefined) || ""}
          />
        ))}
      </Field>
      {touched[name] && !!errors[name] && <FormHelperText error={true}>{errors[name]}</FormHelperText>}
    </FormControl>
  );
}

export { RadioGroupField };
