import * as React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { JobRequestFile, JobRequestsService } from "../../../gen/clients/llts";

interface Props {
  jobRequestId: string;
  files: JobRequestFile[] | undefined
}

/**
 * Represents a component that renders a list of files associated with a job request.
 *
 * @component
 * @param {Object} Props - The properties of the component.
 * @param {string} Props.jobRequestId - The ID of the job request.
 * @param {Array} Props.files - The list of files associated with the job request.
 */
const JobRequestFileList: React.FC<Props> = ({ jobRequestId, files }) => {
  const onFileLinkClick = React.useCallback(async (file: JobRequestFile) => {
    const downloadUrlResponse = await JobRequestsService.getJobRequestFileDownloadUrl({
      jobRequestId,
      fileKey: encodeURIComponent(file.fileKey)
    });
    const downloadUrl = downloadUrlResponse.downloadUrl;
    if (downloadUrl) {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.click();
    }
  }, [jobRequestId]);

  return (
    <Stack spacing={0.5}>
      {files?.map(f => (
        <Typography key={f.fileName} variant="body2">
          <Link href="#" onClick={() => onFileLinkClick(f)}>
            {f.fileName}
          </Link>
        </Typography>
      ))}
    </Stack>

  );
};

export { JobRequestFileList };
